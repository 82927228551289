import { httpClient } from './http/http-client';
import { EnabledTypeState, NotificationSettingType } from './models';

const getNotificationSettings = (): Promise<NotificationSettingType[]> => {
  return httpClient.get('/UserNotificationSetting');
};

const updateNotification = (id: number, newSettings: EnabledTypeState): Promise<void> => {
  return httpClient.put(`/UserNotificationSetting/${id}`, newSettings);
};

export {
  getNotificationSettings,
  updateNotification,
};
