/* eslint-disable padding-line-between-statements */
/* eslint-disable curly */
import * as Yup from 'yup';

export const reminderSchema = Yup.object({
  subject: Yup.string().required('This field is required!'),
  note: Yup.string(),
  time: Yup.string().required('This field is required'),
  type: Yup.object().required('This field is required'),
  priority: Yup.object().required('This field is required'),
  contactsId: Yup.array(),
  property: Yup.object(),
  dealPipline: Yup.object(),
});
