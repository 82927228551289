import { httpClient } from 'app/api/http/http-client';
import { MyPropertyQueryResponse, MyProperty, ContactListQueryResponse, ContactList } from './_models';
import axios from 'axios';

const getMyProperties = (query: string): Promise<MyPropertyQueryResponse> => {
  return httpClient.get(`/MyProperties?${query}`);
};

const getMyPropertiesMinimal = (query: string): Promise<MyPropertyQueryResponse> => {
  return httpClient.get(`/MyProperties/minimal?${query}`);
};

const createExport = () => {
  return httpClient.post('/MyProperties/Export');
};

const getMyPropertyList = (): Promise<ContactListQueryResponse> => {
  return httpClient.get('/MyPropertyGroup');
};

const getMyPropertyById = (id: number): Promise<MyProperty> => {
  return httpClient.get(`/MyProperties/${id}`);
};

const createMyProperty = (body: MyProperty): Promise<boolean> => {
  return httpClient.post('/MyProperties', body);
};

const createMyPropertyList = (body: ContactList): Promise<boolean> => {
  return httpClient.post('/MyPropertyGroup', body.name);
};

const updateMyProperty = (body: MyProperty): Promise<boolean> => {
  return httpClient.put(`/MyProperties/${body.id}`, body);
};

const updateMyPropertyList = (id: number, name: string): Promise<boolean> => {
  return httpClient.put(`/MyPropertyGroup/${id}/name`, name);
};

const deleteSelectedMyProperties = (ids: number[]): Promise<void> => {
  const requests = ids.map((id) => httpClient.delete(`/UserContact/${id}`));
  return axios.all(requests).then(() => {});
};

const deleteMyPropertyListItem = (id: number): Promise<void> => {
  return httpClient.delete(`/MyPropertyGroup/${id}`);
};

const moveTo = (body: any) => {
  return httpClient.put(`/MyProperties/list/${body.listId}`, body.list);
};

export {
  getMyProperties,
  getMyPropertyList,
  getMyPropertyById,
  createMyProperty,
  createMyPropertyList,
  updateMyProperty,
  updateMyPropertyList,
  deleteSelectedMyProperties,
  deleteMyPropertyListItem,
  getMyPropertiesMinimal,
  moveTo,
  createExport,
};
