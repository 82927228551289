import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Drawer, Box, TextField } from '@mui/material';
import { drawerBody, drawerTextArea } from './DetailsStyle.sx';
import ClearIcon from '@mui/icons-material/Clear';
import { User, DirectSend, Sms, Notification, Direct, Call, Briefcase } from 'app/components/SvgIcons';
import { SendSMS } from '../../Contacts/components/sendsms/SendSMS';
import { SendEmail } from '../../Contacts/components/sendemail/SendEmail';
import { RequestInfo } from '../core/_models';

interface Props {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
  requestInfo: RequestInfo;
}

const Details = ({ userId, isOpen, onClose, requestInfo }: Props) => {
  const navigate = useNavigate();

  const [isOpenSendEmail, setIsOpenSendEmail] = useState(false);
  const [isOpenSendSMS, setIsOpenSendSMS] = useState(false);

  const onAddReminder = () => navigate(`/dashboard/reminders?ContactId=${userId}`);

  return (
    <>
      <Drawer anchor="right" variant="temporary" open={isOpen} onClose={onClose}>
        {requestInfo ? (
          <Box sx={drawerBody}>
            <div className="d-flex align-items-center justify-content-end mb-4">
              <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={onClose}>
                <ClearIcon />
              </Button>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-4">
              <User className="fs-5" />
              <h3 className="fw-bold fs-5 text-black p-0 m-0 ms-2">{requestInfo.fullName}</h3>
            </div>

            <div className="row g-2 mb-4">
              <div className="col-12 col-sm-4">
                <Button
                  className="bg-success text-nowrap w-100"
                  size="small"
                  disabled={!requestInfo.email}
                  endIcon={<DirectSend className="fs-5" />}
                  onClick={() => setIsOpenSendEmail(true)}
                >
                  Send Email
                </Button>
              </div>
              <div className="col-12 col-sm-4">
                <Button
                  className="bg-info text-nowrap w-100"
                  size="small"
                  endIcon={<Sms className="fs-5" />}
                  disabled={!requestInfo.phoneNumber}
                  onClick={() => setIsOpenSendSMS(true)}
                >
                  Send SMS
                </Button>
              </div>
              <div className="col-12 col-sm-4">
                <Button
                  className="bg-warning text-nowrap w-100"
                  size="small"
                  endIcon={<Notification className="fs-5" />}
                  onClick={onAddReminder}
                >
                  Add Reminder
                </Button>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between py-3">
              <div className="d-flex align-items-center">
                <Direct className="fs-5" />
                <span className="fs-6 text-black p-0 m-0 ms-2">Email</span>
              </div>
              <span className="fs-6 text-black ps-1">{requestInfo.email}</span>
            </div>

            <div className="d-flex align-items-center justify-content-between py-3">
              <div className="d-flex align-items-center">
                <Call className="fs-5" />
                <span className="fs-6 text-black p-0 m-0 ms-2">Phone Number</span>
              </div>
              <span className="fs-6 text-black ps-1">{requestInfo.phoneNumber}</span>
            </div>

            <div className="d-flex align-items-center justify-content-between py-3">
              <div className="d-flex align-items-center">
                <Briefcase className="fs-5" />
                <span className="fs-6 text-black p-0 m-0 ms-2">Title</span>
              </div>
              <span className="fs-6 text-black ps-1">{requestInfo.title}</span>
            </div>

            <div className="w-100 my-3">
              <TextField
                multiline={true}
                sx={drawerTextArea}
                placeholder="Add a note"
                label="Note"
                disabled
                value={requestInfo.note}
              />
            </div>
          </Box>
        ) : (
          <Box sx={drawerBody}>Request info unavailable!</Box>
        )}
      </Drawer>
      <SendEmail isOpenDrawer={isOpenSendEmail} setIsOpenDrawer={setIsOpenSendEmail} />
      <SendSMS isOpenDrawer={isOpenSendSMS} setIsOpenDrawer={setIsOpenSendSMS} />
    </>
  );
};

export default Details;
