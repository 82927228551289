import { messaging } from '../config/firebase-config';
import { getToken, MessagePayload, onMessage } from 'firebase/messaging';
import logger from '../app/utils/logger';

export const requestNotificationPermission = async (): Promise<string> => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      return await getToken(messaging, {
        vapidKey: 'BCkZxqFUNhLe0O9ZX32mGOIOw78lyanxaWS4B8zlesfc8eeaELz-BYOKmY1xy1p7z5dcGWdklbvbdTEitYNwcjg',
      });
    }
    logger.error('Notification permission denied');
  } catch (error) {
    logger.error('Error requesting notification permission:', error);
    throw error;
  }
};

export const onMessageListener = (): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
