import * as React from 'react';
import type { SVGProps } from 'react';
const Stage = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7.0026 13.2668V2.7335C7.0026 1.7335 6.57594 1.3335 5.51594 1.3335H2.8226C1.7626 1.3335 1.33594 1.7335 1.33594 2.7335V13.2668C1.33594 14.2668 1.7626 14.6668 2.8226 14.6668H5.51594C6.57594 14.6668 7.0026 14.2668 7.0026 13.2668Z"
        />
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.6667 7.26683V2.7335C14.6667 1.7335 14.24 1.3335 13.18 1.3335H10.4867C9.42667 1.3335 9 1.7335 9 2.7335V7.26683C9 8.26683 9.42667 8.66683 10.4867 8.66683H13.18C14.24 8.66683 14.6667 8.26683 14.6667 7.26683Z"
        />
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.6667 13.2665V12.0665C14.6667 11.0665 14.24 10.6665 13.18 10.6665H10.4867C9.42667 10.6665 9 11.0665 9 12.0665V13.2665C9 14.2665 9.42667 14.6665 10.4867 14.6665H13.18C14.24 14.6665 14.6667 14.2665 14.6667 13.2665Z"
        />
    </svg>
);
export default Stage;