/* eslint-disable curly */
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAllProperties } from 'app/utils/services/propertyService';
import { useEffect, useState } from 'react';
import {
  useAddListingDraft,
  useGetAddListingData,
  useAddListeningContinue,
} from 'app/utils/services/addListingService';
import useMediaQuery from '@mui/material/useMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import { residentialSchema } from './residentialSchema';

const usePropertyInformation = () => {
  const location = useLocation();
  const role = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const [lat, setLat] = useState<string | undefined>();
  const [lng, setLng] = useState<string | undefined>();
  const [postalCode, setPostalCode] = useState('');
  const [switchValue, setSwitchValue] = useState('SF');
  const [lotSizeValue, setLotSizeValue] = useState('');
  const [listingId, setListingId] = useState<string | undefined>();
  const [openInfo, setOpenInfo] = useState(true);
  const [openAddress, setOpenAddress] = useState(true);
  const [openDetail, setOpenDetail] = useState(true);
  const isMobile = useMediaQuery('(max-width: 992px)');

  useEffect(() => {
    if (!isMobile && !openInfo) {
      setOpenInfo(true);
    }
    if (!isMobile && !openAddress) {
      setOpenAddress(true);
    }
    if (!isMobile && !openDetail) {
      setOpenDetail(true);
    }
  }, [isMobile]);

  const handleClick = (set: any, value: boolean) => {
    if (isMobile) {
      set(!value);
    }
  };

  const token = localStorage.getItem('token');
  const sessionId: string = sessionStorage.getItem(role === 'commercial' ? 'commercialId' : 'residentialId');

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(residentialSchema(role === 'residential')) });

  const { isLoadingAllProperties, dataAllProperties, isErrorAllProperties } = useGetAllProperties(
    role === 'residential' ? 2 : role === 'commercial' && 1
  );
  const { isLoadingDraft, isSuccessDraft, dataDraft, draftMutation } = useAddListingDraft();
  const { isLoadingContinue, isSuccessContinue, dataContinue, continueMutation } = useAddListeningContinue();

  const { isLoadingGetAddListingData, dataGetAddListingData, dataGetAddListingError } = useGetAddListingData(
    role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
   listingId || sessionId,
    {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    }
  );

  useEffect(() => {
    if (
      // sessionId !== null &&
      dataGetAddListingData !== undefined &&
      !isLoadingGetAddListingData &&
      !isLoadingAllProperties &&
      !isErrorAllProperties
    ) {
      setValue('propertyName', dataGetAddListingData?.information.name || '');
      setValue('fullAddress', dataGetAddListingData?.information.fullAddress || '');
      setValue('street', dataGetAddListingData?.information.street || '');
      setValue('city', dataGetAddListingData?.information.city || '');
      setValue('state', dataGetAddListingData?.information.state || '');
      setValue('zipCode', dataGetAddListingData?.information.postalCode || '');
      setValue('country', dataGetAddListingData?.information.country || '');
      setValue('county', dataGetAddListingData?.information.county || '');

      setValue('totalSqFt', dataGetAddListingData?.information.totalSqFt || '');
      setValue('usableSqFT', dataGetAddListingData?.information.usableSqFT || '');
      setValue('units', dataGetAddListingData?.information.units || '');

      setValue('stories', dataGetAddListingData?.information.stories || '');
      setValue('parkingSpaces', dataGetAddListingData?.information.parkingSpaces || '');
      setValue('zoning', dataGetAddListingData?.information.zoning || '');
      setValue('yearRenovated', dataGetAddListingData?.information.yearRenovated || '');
      setValue('bathroom', dataGetAddListingData?.information.bathroom || '');
      setValue('bedroom', dataGetAddListingData?.information.bedroom || '');
      setValue('yearBuilt', dataGetAddListingData?.information.yearBuilt || '');
      setValue('lotSize', dataGetAddListingData?.information.lotSize || '');

      setLotSizeValue(dataGetAddListingData?.information.lotSize || '');
      setLat(dataGetAddListingData?.information.latitude || '');
      setLng(dataGetAddListingData?.information.longitude || '');

      // Find object fields index and set datas
      const typeIndex = dataAllProperties?.type?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.type
      );
      if (typeIndex > -1) {
        setValue('propertyType', {
          id: dataAllProperties?.type[typeIndex]?.id,
          name: dataAllProperties?.type[typeIndex]?.name,
        });
      } else {
        setValue('propertyType', undefined);
      }
      const ocupancyIndex = dataAllProperties?.ocuppancy?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.occupancy
      );

      if (ocupancyIndex > -1) {
        setValue('occupancy', {
          id: dataAllProperties?.ocuppancy[ocupancyIndex]?.id,
          name: dataAllProperties?.ocuppancy[ocupancyIndex]?.name,
        });
      } else {
        setValue('occupancy', undefined);
      }

      const buildingClassIndex = dataAllProperties?.buildingClass?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.buildingClass
      );
      if (buildingClassIndex > -1) {
        setValue('buildingClass', {
          id: dataAllProperties?.buildingClass[buildingClassIndex]?.id,
          name: dataAllProperties?.buildingClass[buildingClassIndex]?.name,
        });
      } else {
        setValue('buildingClass', undefined);
      }

      const parkingClassIndex = dataAllProperties?.parking?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.parking
      );
      if (parkingClassIndex > -1) {
        setValue('parking', {
          id: dataAllProperties?.parking[parkingClassIndex]?.id,
          name: dataAllProperties?.parking[parkingClassIndex]?.name,
        });
        setValue('heating', {
          id: dataAllProperties?.heating[parkingClassIndex]?.id,
          name: dataAllProperties?.heating[parkingClassIndex]?.name,
        });
        setValue('cooling', {
          id: dataAllProperties?.cooling[parkingClassIndex]?.id,
          name: dataAllProperties?.cooling[parkingClassIndex]?.name,
        });
      } else {
        setValue('parking', undefined);
        setValue('heating', undefined);
        setValue('cooling', undefined);
      }

      const styleClassIndex = dataAllProperties?.style?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.style
      );
      if (styleClassIndex > -1) {
        setValue('style', {
          id: dataAllProperties?.style[parkingClassIndex]?.id,
          name: dataAllProperties?.style[parkingClassIndex]?.name,
        });
      } else {
        setValue('style', undefined);
      }

      const roofingClassIndex = dataAllProperties?.roofing?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.roofing
      );
      if (roofingClassIndex > -1) {
        setValue('roofing', {
          id: dataAllProperties?.roofing[roofingClassIndex]?.id,
          name: dataAllProperties?.roofing[roofingClassIndex]?.name,
        });
      } else {
        setValue('roofing', undefined);
      }

      const constructionClassIndex = dataAllProperties?.construction?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.construction
      );
      if (constructionClassIndex > -1) {
        setValue('construction', {
          id: dataAllProperties?.construction[constructionClassIndex]?.id,
          name: dataAllProperties?.construction[constructionClassIndex]?.name,
        });
      } else {
        setValue('construction', undefined);
      }

      const foundationClassIndex = dataAllProperties?.foundation?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.foundation
      );
      if (foundationClassIndex > -1) {
        setValue('foundation', {
          id: dataAllProperties?.foundation[foundationClassIndex]?.id,
          name: dataAllProperties?.foundation[foundationClassIndex]?.name,
        });
      } else {
        setValue('foundation', undefined);
      }

      const basementClassIndex = dataAllProperties?.basement?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.basement
      );
      if (basementClassIndex > -1) {
        setValue('basement', {
          id: dataAllProperties?.basement[basementClassIndex]?.id,
          name: dataAllProperties?.basement[basementClassIndex]?.name,
        });
      } else {
        setValue('basement', undefined);
      }
    }

    if ((dataGetAddListingError as any)?.status === 404) {
      sessionStorage.removeItem(role === 'commercial' ? 'commercialId' : 'residentialId');
      if (window !== undefined) window.location.reload();
    }
  }, [
    dataGetAddListingData,
    isLoadingAllProperties,
    isLoadingGetAddListingData,
    dataGetAddListingError,
    isErrorAllProperties,
  ]);

  useEffect(() => {
    if (sessionId === null && !isErrorAllProperties) {
      setValue('occupancy', { id: dataAllProperties?.ocuppancy[0]?.id, name: dataAllProperties?.ocuppancy[0]?.name });
      setValue('parking', { id: dataAllProperties?.parking[0]?.id, name: dataAllProperties?.parking[0]?.name });
      setValue('style', { id: dataAllProperties?.style[0]?.id, name: dataAllProperties?.style[0]?.name });
      setValue('heating', { id: dataAllProperties?.heating[0]?.id, name: dataAllProperties?.heating[0]?.name });
      setValue('roofing', { id: dataAllProperties?.roofing[0]?.id, name: dataAllProperties?.roofing[0]?.name });
      setValue('cooling', { id: dataAllProperties?.cooling[0]?.id, name: dataAllProperties?.cooling[0]?.name });
      setValue('construction', {
        id: dataAllProperties?.construction[0]?.id,
        name: dataAllProperties?.construction[0]?.name,
      });
      setValue('foundation', {
        id: dataAllProperties?.foundation[0]?.id,
        name: dataAllProperties?.foundation[0]?.name,
      });
      setValue('basement', { id: dataAllProperties?.basement[0]?.id, name: dataAllProperties?.basement[0]?.name });
      setValue('buildingClass', {
        id: dataAllProperties?.buildingClass[0]?.id,
        name: dataAllProperties?.buildingClass[0]?.name,
      });
    }
  }, [dataAllProperties, isErrorAllProperties]);

  useEffect(() => {
    if (
      (isSuccessDraft && dataDraft !== undefined && !dataDraft.message) ||
      (isSuccessContinue && dataContinue !== undefined && !dataContinue.message)
    ) {
      sessionStorage.setItem(
        role === 'commercial' ? 'commercialId' : 'residentialId',
        dataDraft ? String(dataDraft) : String(dataContinue)
      );
      navigate(`/add-listing/${role}/marketing-description`);
    }
  }, [dataDraft, dataContinue]);

  const handleChangeSwitch = (value: string) => {
    setSwitchValue(value);

    if (value === 'AC') {
      setLotSizeValue(`${+lotSizeValue / 43560}`);
    }

    if (value === 'SF') {
      setLotSizeValue(`${+lotSizeValue * 43560}`);
    }
  };

  const handleChangeLotSize = (e: any) => {
    setLotSizeValue(e.target.value);
  };

  const handleChangeType = (newValue: any) => {
    setValue('propertyType', newValue);
  };

  const onPlaceChanged = (place: google.maps.places.PlaceResult | null) => {
    if (place !== null) {
      const latitude = place.geometry.location.lat();
      const longtitude = place.geometry.location.lng();
      let street = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let country = '';
      let county = '';
      if (place.address_components.find(({ types }: any) => types[0] === 'street_number')) {
        street = place.address_components.find(({ types }: any) => types[0] === 'street_number').long_name;
      }

      if (place.address_components.find(({ types }: any) => types[0] === 'route')) {
        street = `${street} ${place.address_components.find(({ types }: any) => types[0] === 'route').long_name}`;
      }
      if (place.address_components.find(({ types }: any) => types[0] === 'locality')) {
        city = place.address_components.find(({ types }: any) => types[0] === 'locality').long_name;
      }

      if (place.address_components.find(({ types }: any) => types[0] === 'administrative_area_level_1')) {
        state = place.address_components.find(({ types }: any) => types[0] === 'administrative_area_level_1').long_name;
      }

      if (place.address_components.find(({ types }: any) => types[0] === 'country')) {
        country = place.address_components.find(({ types }: any) => types[0] === 'country').long_name;
      }
      if (place.address_components.find(({ types }: any) => types[0] === 'postal_code')) {
        zipCode = place.address_components.find(({ types }: any) => types[0] === 'postal_code').long_name;
      }
      if (place.address_components.find(({ types }: any) => types[0] === 'administrative_area_level_2')) {
        county = place.address_components.find(
          ({ types }: any) => types[0] === 'administrative_area_level_2'
        ).long_name;
      }

      setValue('street', street);
      setValue('city', city);
      setValue('state', state);
      setValue('zipCode', zipCode);
      setValue('country', country);
      setValue('county', county);
      setLat(String(latitude));
      setLng(String(longtitude));
      setPostalCode(zipCode);
    } else {
      alert('Please enter text');
    }
  };
  const submitForm = (data: any, event: any) => {
    const element = event.target as HTMLElement;
    const isDraft = element?.dataset?.isdraft === 'true';
    const residentialFormData = new FormData();
    residentialFormData.append('Information.Name', data.propertyName);
    residentialFormData.append('Information.FullAddress', data.fullAddress);
    residentialFormData.append('Information.Street', data.street);
    residentialFormData.append('Information.City', data.city);
    residentialFormData.append('Information.State', data.state);
    residentialFormData.append('Information.PostalCode', data.zipCode);
    residentialFormData.append('Information.Country', data.country);
    residentialFormData.append('Information.County', data.county);
    residentialFormData.append(
      'Information.Type',
      typeof getValues('propertyType') === 'number' ? getValues('propertyType') : data.propertyType?.id
    );
    residentialFormData.append(
      'Information.Occupancy',
      typeof getValues('occupancy') === 'number' ? getValues('occupancy') : data.occupancy?.id
    );
    residentialFormData.append('Information.Longitude', lng);
    residentialFormData.append('Information.Latitude', lat);
    residentialFormData.append('Information.TotalSqFt', data.totalSqFt);
    residentialFormData.append('Information.LotSize', data.lotSize);
    residentialFormData.append('Information.YearBuilt', data.yearBuilt);
    residentialFormData.append(
      'Information.Parking',
      typeof getValues('parking') === 'number' ? getValues('parking') : data.parking?.id
    );
    residentialFormData.append(
      'Information.Style',
      typeof getValues('style') === 'number' ? getValues('style') : data.style?.id
    );
    residentialFormData.append(
      'Information.Roofing',
      typeof getValues('roofing') === 'number' ? getValues('roofing') : data.roofing?.id
    );
    residentialFormData.append(
      'Information.Heating',
      typeof getValues('heating') === 'number' ? getValues('heating') : data.heating?.id
    );
    residentialFormData.append(
      'Information.Cooling',
      typeof getValues('cooling') === 'number' ? getValues('cooling') : data.cooling?.id
    );
    residentialFormData.append(
      'Information.Basement',
      typeof getValues('basement') === 'number' ? getValues('basement') : data.basement?.id
    );
    residentialFormData.append(
      'Information.Foundation',
      typeof getValues('foundation') === 'number' ? getValues('foundation') : data.foundation?.id
    );
    residentialFormData.append(
      'Information.Construction',
      typeof getValues('construction') === 'number' ? getValues('construction') : data.construction?.id
    );
    residentialFormData.append('Information.Bathroom', data.bathroom);
    residentialFormData.append('Information.Bedroom', data.bedroom);
    residentialFormData.append('Id', sessionId === null ? '' : sessionId);

    const commercialFormData = new FormData();
    commercialFormData.append('Information.Name', data.propertyName);
    commercialFormData.append('Information.FullAddress', data.fullAddress);
    commercialFormData.append('Information.Street', data.street);
    commercialFormData.append('Information.City', data.city);
    commercialFormData.append('Information.State', data.state);
    commercialFormData.append('Information.PostalCode', data.zipCode);
    commercialFormData.append('Information.Country', data.country);
    commercialFormData.append('Information.County', data.county);
    commercialFormData.append('Information.Type', data.propertyType?.id);
    commercialFormData.append('Information.Longitude', lng);
    commercialFormData.append('Information.Latitude', lat);
    commercialFormData.append('Information.TotalSqFt', data.totalSqFt);
    commercialFormData.append('Information.UsableSqFt', data.usableSqFT);
    commercialFormData.append('Information.Units', data.units);
    commercialFormData.append('Information.LotSize', data.lotSize);
    commercialFormData.append(
      'Information.BuildingClass',
      typeof getValues('buildingClass') === 'number' ? getValues('buildingClass') : data.buildingClass?.id
    );
    commercialFormData.append('Information.Stories', data.stories);
    commercialFormData.append('Information.ParkingSpaces', data.parkingSpaces);
    commercialFormData.append('Information.Zoning', data.zoning);
    commercialFormData.append('Information.YearRenovated', data.yearRenovated);
    commercialFormData.append('Information.YearBuilt', data.yearBuilt);
    commercialFormData.append('Id', sessionId === null ? '' : sessionId);

    if (isDraft) {
      draftMutation({
        data: role === 'commercial' ? commercialFormData : role === 'residential' && residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      continueMutation({
        data: role === 'commercial' ? commercialFormData : role === 'residential' && residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  };

  const onSubmit = handleSubmit((data: any, event: any) => submitForm(data, event));

  return {
    control,
    register,
    role,
    onPlaceChanged,
    isLoadingContinue,
    isLoadingDraft,
    isLoadingAllProperties,
    isLoadingGetAddListingData,
    dataAllProperties,
    onSubmit,
    setValue,
    getValues,
    postalCode,
    switchValue,
    lotSizeValue,
    handleChangeSwitch,
    handleChangeLotSize,
    openInfo,
    setOpenInfo,
    openDetail,
    setOpenDetail,
    openAddress,
    setOpenAddress,
    handleClick,
    isMobile,
    handleChangeType,
    errors,
    setListingId
  };
};

export default usePropertyInformation;
