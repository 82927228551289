import * as React from 'react';
import type { SVGProps } from 'react';
const ThreeSquare = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M8.0026 11.3333V12.92C8.0026 14.1667 7.5026 14.6667 6.24927 14.6667H3.0826C1.83594 14.6667 1.33594 14.1667 1.33594 12.92V9.75333C1.33594 8.5 1.83594 8 3.0826 8H4.66927V9.58C4.66927 10.8333 5.16927 11.3333 6.41594 11.3333H8.0026Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5} />
        <path
            d="M11.3307 8.00008V9.58008C11.3307 10.8334 10.8307 11.3334 9.5774 11.3334H6.41073C5.16406 11.3334 4.66406 10.8334 4.66406 9.58008V6.41341C4.66406 5.16675 5.16406 4.66675 6.41073 4.66675H7.9974V6.24675C7.9974 7.50008 8.4974 8.00008 9.74406 8.00008H11.3307Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5} />
        <path
            d="M14.6667 3.07992V6.24658C14.6667 7.49992 14.1667 7.99992 12.9133 7.99992H9.74667C8.5 7.99992 8 7.49992 8 6.24658V3.07992C8 1.83325 8.5 1.33325 9.74667 1.33325H12.9133C14.1667 1.33325 14.6667 1.83325 14.6667 3.07992Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5} />
    </svg>
);
export default ThreeSquare;
