import React, { ChangeEvent, useEffect, useState } from 'react';
import PropertyRequests from './components/property-requests';
import CustomInput from '../../../components/CustomInput';
import { useDebounce } from '../../../constants/helpers';
import { useQueryRequest } from '../MyProperty/core/QueryRequestProvider';

const ListingsRequestsPage = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const { updateState } = useQueryRequest();
  const debouncedSearchTerm = useDebounce(searchQuery, 150);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchQuery !== undefined) {
      updateState({ Address: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  return (
    <>
      <div className="flex flex-col col-12 col-md-4">
        <CustomInput
          label="Search by address"
          value={searchQuery}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
        />
      </div>
      <PropertyRequests />
    </>
  );
};

export { ListingsRequestsPage };
