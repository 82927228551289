import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { Box, Button, Drawer } from '@mui/material';

export const StyledCard = styled(Card)({
  marginBottom: '8px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
  borderRadius: '12px',
  overflow: 'visible',
});

export const DetailButton = styled(Button)({
  backgroundColor: '#FFD700',
  color: '#000',
  textTransform: 'none',
  // borderRadius: '16px',
  padding: '8px 14px',
  minWidth: 'unset',
  '&:hover': {
    backgroundColor: '#FFD700',
    opacity: 0.9,
  },
});

export const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '530px',
    padding: '1rem',
    backgroundColor: '#fff',
    boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.1)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)', // Very light backdrop
  },
});

export const DrawerContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  backgroundColor: '#fff',
}));

export const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '2rem',
});

export const UserContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2rem',
  gap: '0.5rem',
});

export const InfoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem 0',
});

export const InfoLabel = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
});