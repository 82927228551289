import React, { useState } from 'react';
import { Box, Typography, TextField, Avatar, Card, CardContent, Stack, CircularProgress, Button } from '@mui/material';
import { useQuery } from 'react-query';
import { getUserDetails } from '../../../api/account-settings/requests';
import AccountSettingEditIcon from '../../../components/SvgIcons/AccountSettingEdit';
import UpdateUserDrawer from './update-user-drawer';
import UpdateCompanyDrawer from './update-company-drawer';
import PasswordChangeDrawer from './password-change-drawer';

const AccountSettingsPage = () => {
  const { data: userProfile, error, isLoading } = useQuery('userDetails', getUserDetails);
  const [openUserUpdateDrawer, setOpenUserUpdateDrawer] = useState(false);
  const [openCompanyUpdateDrawer, setOpenCompanyUpdateDrawer] = useState(false);
  const [opnPasswdChange, setOpnPasswdChange] = useState(false);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error fetching activities
      </Typography>
    );
  }

  const handlePasswdChange = () => {
    setOpnPasswdChange(true);
    setOpenUserUpdateDrawer(false);
  };

  return (
    <>
      <Box sx={{ maxWidth: 800, p: 3 }}>
        {/* Account Settings Section */}
        <Card sx={{ mb: 1 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6" component="h2">
                Account Settings
              </Typography>
              <Button
                sx={{
                  color: 'text.secondary',
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: 'grey.100',
                  },
                }}
                startIcon={<AccountSettingEditIcon />}
                onClick={() => setOpenUserUpdateDrawer(true)}
              >
                Edit
              </Button>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
              <Avatar src={userProfile.logo} sx={{ width: 80, height: 80, mr: 2 }} />
            </Box>

            <Stack spacing={3}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField fullWidth label="Name" value={userProfile.name} InputProps={{ readOnly: true }} />
                <TextField fullWidth label="Lastname" value={userProfile.lastName} InputProps={{ readOnly: true }} />
              </Box>

              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField fullWidth label="Email Address" value={userProfile.email} InputProps={{ readOnly: true }} />
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={userProfile.phoneNumber}
                  InputProps={{ readOnly: true }}
                />
              </Box>
            </Stack>
          </CardContent>
        </Card>

        {/* Company UpdateUserDrawer Section */}
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6" component="h2">
                Company Details
              </Typography>
              <Button
                sx={{
                  color: 'text.secondary',
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: 'grey.100',
                  },
                }}
                startIcon={<AccountSettingEditIcon />}
                onClick={() => setOpenCompanyUpdateDrawer(true)}
              >
                Edit
              </Button>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
              <Avatar src={userProfile.companyLogo} sx={{ width: 80, height: 80, mr: 2 }} />
            </Box>

            <TextField fullWidth label="Company Name" value={userProfile.companyName} InputProps={{ readOnly: true }} />
          </CardContent>
        </Card>
      </Box>
      {openUserUpdateDrawer && (
        <UpdateUserDrawer
          userDetails={userProfile}
          isOpen={openUserUpdateDrawer}
          onPasswdChange={handlePasswdChange}
          onClose={() => setOpenUserUpdateDrawer(false)}
        />
      )}
      {openCompanyUpdateDrawer && (
        <UpdateCompanyDrawer
          isOpen={openCompanyUpdateDrawer}
          onClose={() => setOpenCompanyUpdateDrawer(false)}
          userDetails={userProfile}
        />
      )}
      {opnPasswdChange && <PasswordChangeDrawer isOpen={opnPasswdChange} onClose={() => setOpnPasswdChange(false)} />}
    </>
  );
};

export default AccountSettingsPage;
