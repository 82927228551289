import React from 'react';
import { Box, Typography, Paper, CircularProgress } from '@mui/material';
import NotificationSetting from './components/NotificationSetting';
import { useQuery } from 'react-query';
import { getNotificationSettings } from '../../../api/notifications';
import { NotificationSettings } from '../../../api/models';

const NotificationSettingsPage = () => {
  const {
    data: notifications,
    isLoading,
    error,
  } = useQuery<NotificationSettings>({
    queryKey: ['notificationSettings'],
    queryFn: getNotificationSettings,
  });

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Box sx={{ p: 2, color: 'error.main' }}>Error loading notification settings. Please try again later.</Box>;
  }

  return (
    <Paper sx={{ maxWidth: 600, borderRadius: 3 }}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Notification Settings
        </Typography>

        {notifications.map((n, idx) => (
          <NotificationSetting key={idx} notification={n} />
        ))}
      </Box>
    </Paper>
  );
};

export default NotificationSettingsPage;
