/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { FC, useMemo } from 'react';
import { ID } from 'app/constants/models';
import { useListView } from '../../../core/ListViewProvider';

type Props = {
  id: ID;
};

const DealPipelineSelectionCell: FC<Props> = ({ id }) => {
  const { selected, onSelect } = useListView();
  const isSelected = useMemo(() => selected.includes(id), [id, selected]);

  return (
    <div className="form-check form-check-custom form-check-solid">
      <input className="form-check-input" type="checkbox" checked={isSelected} onChange={() => onSelect(id)} />
    </div>
  );
};

export { DealPipelineSelectionCell };
