/* eslint-disable react/react-in-jsx-scope */
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';
import { Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import FieldError from '../FieldError';
import { errorStyles, styles } from './CustomSelect.sx';
import logger from 'app/utils/logger';

interface Props {
  value?: any;
  error?: any;
  onChange?: any;
  options?: any;
  control?: any;
  rest?: any;
  label: string;
  isMulti?: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  optionComponent?: React.ComponentType<any>;
  loadOptions: (inputValue: any, callback: any, { page }: { page: number }) => any;
  defaultValue?: Array<{ value?: string; label?: string; id?: string; name?: string }>;
}

const MoreSelectedBadge = ({ items }: any) => {
  const style = {
    marginLeft: 'auto',
    background: '#d4eefa',
    borderRadius: '4px',
    fontSize: '11px',
    padding: '3px',
    order: 99,
  };

  const title = items.join(', ');
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? 's' : ''} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }: any) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x: any) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      {children.map((child: any) => (child && child.key !== 'placeholder' ? child : null))}
    </components.ValueContainer>
  );
};

const contactOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: "5px", cursor: "pointer" }}>
      <div style={{ fontWeight: "bold" }}>{data.name}</div>
      {data.address &&
        <div style={{ fontSize: "0.85em", color: "#555" }}>Address: {data.address}</div>}
      {data.tags &&
        <div style={{ fontSize: "0.85em", color: "#555" }}>Tags: {data.tags}</div>}
    </div>
  );
};

const propertyOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: "10px", cursor: "pointer" }}>
      <div style={{ fontWeight: "bold" }}>{data.name}</div>
      {data.address &&
        <div style={{ fontSize: "0.85em", color: "#555" }}>Address: {data.address}</div>}
      {(data.type || data.group) &&
        <div style={{ fontSize: "0.85em", color: "#555" }}>{data.group && (`Group: ${data.group};`)} {data.type && (`Type: ${data.type}`)}</div>}
    </div>
  );
};

const dealPiplineOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: "10px", cursor: "pointer" }}>
      <div style={{ fontWeight: "bold" }}>{data.name}</div>
      {data.address &&
        <div style={{ fontSize: "0.85em", color: "#555" }}>Address: {data.address}</div>}
      <div style={{ fontSize: "0.85em", color: "#555" }}>Stage: {data.stage}; {data.type && (`Type: ${data.type}`)}</div>
    </div>
  );
};

const defaultOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: "10px", cursor: "pointer" }}>
      <div style={{ fontWeight: "bold" }}>{data.name} </div>
    </div>
  );
};

const AsyncSelectPagination = (props: Props) => {
  const { control, rest, label, error, isMulti, disabled, loadOptions, isSearchable } = props;
  return (
    <Box sx={{ height: '70px', width: '100%' }}>
      {control !== undefined && (
        <Controller
          control={control}
          render={({ field: { onChange: onChangeInput, ref, value } }) => (
            <AsyncPaginate
              placeholder={label}
              styles={error !== undefined ? errorStyles : styles}
              isDisabled={disabled}
              components={{
                IndicatorSeparator: () => null,
                ValueContainer: CustomValueContainer,
                Option: props.optionComponent ?? defaultOption,
                MultiValue,
              }}
              onChange={(selectedValue: any) => {

                onChangeInput(selectedValue);
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isMulti={isMulti}
              isClearable={false}
              isSearchable={isSearchable}
              hideSelectedOptions={false}
              loadOptions={loadOptions}
              defaultOptions
              additional={{
                page: 0,
              }}
              value={value}
            />
          )}
          name={rest.name}
        />
      )}
      <FieldError error={error} />
    </Box>
  );
};

export { AsyncSelectPagination, contactOption, propertyOption, dealPiplineOption };
