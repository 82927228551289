/* eslint-disable curly */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { QUERIES } from 'app/constants/consts';
import { contactSchema } from './_schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { DealPipeline, Stages } from './_models';
import { createDealPipline, getDealPiplineById, updateDealPipline, getDealPiplines, getStages } from './_requests';
import { getContacts } from '../../Contacts/core/_requests';
import { useQueryResponse } from './QueryResponseProvider';
import { useStageQueryResponse } from './StageQueryResponse';
import { isNotEmpty } from 'app/constants/helpers';
import { useLoadScript } from '@react-google-maps/api';
import { httpClient } from 'app/api/http/http-client';
import logger from 'app/utils/logger';
import { IListItem } from 'app/utils/services/types';
import { getMyPropertiesMinimal } from '../../MyProperty/core/_requests';


const useDealPipeline = (tools: { [key: string]: any }) => {
  const { refetch } = useQueryResponse();
  const stageListData = useStageQueryResponse();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(contactSchema) });

  const [searchResult, setSearchResult] = useState(null);

  const enabledQuery: boolean = isNotEmpty(tools.itemIdForUpdate);
  const getMyPropertyByIdQuery = useQuery(
    `${QUERIES.DEALPIPELINE_LIST}-property-${tools.itemIdForUpdate}`,
    () => {
      return getDealPiplineById(tools.itemIdForUpdate);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery && tools.itemIsUpdate,
      onSuccess: (data) => {
        if (tools.itemIsUpdate) {
          setValue('name', data.name || '');
          setValue('leadId', data.lead && { id: data.lead.id, name: data.lead.name });
          setValue('propertyTypeId', data.propertyType && { id: data.propertyType.id, name: data.propertyType.name });
          setValue('stageId', data.stage && { id: data.stage.id, name: data.stage.name });
          setValue('contractPrice', data.contractPrice || undefined);
          setValue('emd', data.emd || undefined);
          setValue('expenses', data.expenses || undefined);
          setValue('marketValue', data.marketValue || undefined);
          setValue('transactionType', data.transactionType);
          setValue('pontentialROI', data.pontentialROI || undefined);
          setValue('pontentialROIOverride', data.pontentialROIOverride);
          setValue('emdDeadline', data.emdDeadline || '');
          setValue('dueiligencePeriod', data.dueiligencePeriod || '');
          setValue('closingDate', data.closingDate || '');
          setValue('note', data.notes || '');
          setValue('address.street', data?.address?.street || '');
          setValue('address.city', data?.address?.city || '');
          setValue('address.country', data?.address?.country || '');
          setValue('address.county', data?.address?.county || '');
          setValue('address.postalCode', data?.address?.postalCode || '');
          setValue('address.state', data?.address?.state || '');
          setValue('address.latitude', data?.address?.latitude);
          setValue('address.longitude', data?.address?.longitude);
          setValue('address.fullAddress', data?.address?.fullAddress);

          httpClient.get<IListItem[]>('/PropertyDetails/investment')
            .then(c => {
              if (c) {
                const findeItem = c.findIndex(({ name }: { name: string }) => name === data?.dealType)
                if (findeItem > -1)
                  setValue('dealType', { id: c[findeItem].id, name: c[findeItem].name });
              }
            });

          httpClient.get<IListItem[]>('/PropertyDetails/transaction')
            .then(c => {
              if (c) {
                const findeItem = c.findIndex(({ name }: { name: string }) => name === data?.transactionType)
                if (findeItem > -1)
                  setValue('transactionType', { id: c[findeItem].id, name: c[findeItem].name });
              }
            });
        }
      },
      onError: (err) => {
        logger.error(err);
      },
    }
  );

  const createMyPropertyMutation = useMutation((data: DealPipeline) => createDealPipline(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.DEALPIPELINE_LIST}-CREATE`]);
      setValue('note', '');
      reset();
      tools.onCloseCreate();
      tools.onOpenScankbar(true);
      refetch();
    },
  });

  const updateMyPropertyMutation = useMutation((data: DealPipeline) => updateDealPipline(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.DEALPIPELINE_LIST}-UPDATE-${tools.itemIdForUpdate}`]);
      setValue('note', '');
      reset();
      tools.onCloseCreate();
      tools.onOpenScankbar(true);
      refetch();
      getMyPropertyByIdQuery.refetch();
    },
  });

  const loadStage = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = (await getStages()) as unknown as any[];

    return {
      options: response,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadProperties = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = (await getMyPropertiesMinimal(`PageNumber=${page * 10}&PageSize=10`)) as unknown as any;

    return {
      options: response.values,
      hasMore: response?.totalPages !== page,
      additional: {
        page: page + 1,
      },
    };
  };


  const loadContactsOptions = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = (await getContacts(`PageNumber=${page * 10}&PageSize=10`)) as unknown as any;

    return {
      options: response.values,
      hasMore: response?.totalPages !== page,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadPropertyTypeOptions = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = await httpClient.get('/PropertyDetails/propertyType');

    return {
      options: response,
      hasMore: false,
    };
  };

  const loadInvestmentOptions = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = await httpClient.get('/PropertyDetails/investment');

    return {
      options: response,
      hasMore: false,
    };
  };

  const loadTransactionOptions = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = await httpClient.get('/PropertyDetails/transaction');

    return {
      options: response,
      hasMore: false,
    };
  };



  const onPlaceChanged = (place: google.maps.places.PlaceResult | null) => {
    if (place != null) {
      const latitude = place.geometry.location.lat();
      const longtitude = place.geometry.location.lng();
      let street = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let country = '';
      let county = '';

      if (place.address_components.find(({ types }: any) => types[0] === 'street_number')) {
        street = place.address_components.find(({ types }: any) => types[0] === 'street_number').long_name;
      }

      if (place.address_components.find(({ types }: any) => types[0] === 'route')) {
        street = `${street} ${place.address_components.find(({ types }: any) => types[0] === 'route').long_name}`;
      }
      if (place.address_components.find(({ types }: any) => types[0] === 'locality')) {
        city = place.address_components.find(({ types }: any) => types[0] === 'locality').long_name;
      }

      if (place.address_components.find(({ types }: any) => types[0] === 'administrative_area_level_1')) {
        state = place.address_components.find(({ types }: any) => types[0] === 'administrative_area_level_1').long_name;
      }

      if (place.address_components.find(({ types }: any) => types[0] === 'country')) {
        country = place.address_components.find(({ types }: any) => types[0] === 'country').long_name;
      }
      if (place.address_components.find(({ types }: any) => types[0] === 'postal_code')) {
        zipCode = place.address_components.find(({ types }: any) => types[0] === 'postal_code').long_name;
      }
      if (place.address_components.find(({ types }: any) => types[0] === 'administrative_area_level_2')) {
        county = place.address_components.find(
          ({ types }: any) => types[0] === 'administrative_area_level_2'
        ).long_name;
      }

      setValue('address.street', street);
      setValue('address.city', city);
      setValue('address.country', country);
      setValue('address.county', county);
      setValue('address.postalCode', zipCode);
      setValue('address.state', state);
      setValue('address.latitude', String(latitude));
      setValue('address.longitude', String(longtitude));
      setValue('address.fullAddress', place.formatted_address);
    } else {
      alert('Please enter text');
    }
  };

  const onSubmit = handleSubmit((data: any, event: any) => {
    const element = event.target as HTMLElement;
    const isEdit = element.dataset.isedit;
    const stageId = data.stageId.id;
    const leadId = data.leadId?.id;
    const propertyTypeId = data.propertyTypeId?.id;
    const transactionType = data.transactionType?.id;
    const dealType = data.dealType?.id;



    if (isEdit === 'true')
      updateMyPropertyMutation.mutate({ ...data, stageId, id: tools.itemIdForUpdate, leadId, propertyTypeId,transactionType,dealType });
    else createMyPropertyMutation.mutate({ ...data, stageId, leadId, propertyTypeId,transactionType,dealType });
  });

  return {
    control,
    register,
    getValues,
    errors,
    reset,
    onSubmit,
    createMyPropertyMutation,
    getMyPropertyByIdQuery,
    loadStage,
    loadProperties,
    loadInvestmentOptions,
    loadTransactionOptions,
    loadContactsOptions,
    onPlaceChanged,
    loadPropertyTypeOptions,
  };
};

export { useDealPipeline };
