import React from 'react';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { style, container, navbar } from './SidebarStyles.sx';
import UrexLogoSecond from 'app/components/Icons/UrexLogoSecond';
import {
  Contacts,
  Analytics,
  Reminders,
  Properties,
  PropertyData,
  LeadActivity,
  DealPipeline,
  Ownerlookup,
  EmailCamping,
  TextCamping,
  PostCardCamping,
  AccountSetting,
  NotificationSetting,
  Export,
  Buyers,
} from 'app/components/SvgIcons';

interface Props {
  view?: string;
}

const Sidebar = (props: Props) => {
  const { view } = props;

  return (
    <Box sx={container(view !== undefined)} role={view}>
      <UrexLogoSecond sx={{ m: '0 auto', mb: '40px' }} />
      <Box sx={navbar}>
        {menuItems.map((item) => (
          <NavLink key={item.id} to={item.link} style={({ isActive }) => (isActive ? style.activeLink : style.link)}>
            {item.icon} {item.label}
          </NavLink>
        ))}
      </Box>
    </Box>
  );
};

export const menuItems = [
  // {
  //   id: 1,
  //   label: 'Analytics',
  //   icon: <Analytics className="fs-4 text-gray-600" />,
  //   link: `/dashboard/analytics`,
  // },
  {
    id: 2,
    label: 'Reminders',
    icon: <Reminders className="fs-4 text-gray-600" />,
    link: `/dashboard/reminders`,
  },
  {
    id: 3,
    label: 'My Contacts',
    icon: <Contacts className="fs-4 text-gray-600" />,
    link: `/dashboard/contacts`,
  },
  // {
  //   id: 4,
  //   label: 'Buyers',
  //   icon: <Buyers className="fs-4 text-gray-600" />,
  //   link: `/dashboard/buyers`,
  // },
  {
    id: 5,
    label: 'My Properties',
    icon: <Properties className="fs-4 text-gray-600" />,
    link: `/dashboard/myproperties`,
  },
  {
    id: 6,
    label: 'My Listings',
    icon: <Properties className="fs-4 text-gray-600" />,
    link: `/dashboard/listings`,
  },
  {
    id: 7,
    label: 'Lead Activity',
    icon: <LeadActivity className="fs-4 text-gray-600" />,
    link: `/dashboard/lead-activity`,
  },
  {
    id: 8,
    label: 'Deal Pipeline',
    icon: <DealPipeline className="fs-4 text-gray-600" />,
    link: `/dashboard/deal-pipeline`,
  },
  // {
  //   id: 9,
  //   label: 'Property Data',
  //   icon: <PropertyData className="fs-4 text-gray-600" />,
  //   link: `/dashboard/property-data`,
  // },
  // {
  //   id: 10,
  //   label: 'Owner Lookup',
  //   icon: <Ownerlookup className="fs-4 text-gray-600" />,
  //   link: `/dashboard/owner-lookup`,
  // },
  // {
  //   id: 11,
  //   label: 'Email Campaigns',
  //   icon: <EmailCamping className="fs-4 text-gray-600" />,
  //   link: `/dashboard/email-campaigns`,
  // },
  // {
  //   id: 12,
  //   label: 'Text Campaigns',
  //   icon: <TextCamping className="fs-4 text-gray-600" />,
  //   link: `/dashboard/text-campaigns`,
  // },
  // {
  //   id: 13,
  //   label: 'Postcard Campaigns',
  //   icon: <PostCardCamping className="fs-4 text-gray-600" />,
  //   link: `/dashboard/postcard-campaigns`,
  // },
  {
    id: 14,
    label: 'Account Settings',
    icon: <AccountSetting className="fs-4 text-gray-600" />,
    link: `/dashboard/account-settings`,
  },
  {
    id: 15,
    label: 'Notification Settings',
    icon: <NotificationSetting className="fs-4 text-gray-600" />,
    link: `/dashboard/notifications-settings`,
  },
  {
    id: 16,
    label: 'Exports',
    icon: <Export className="fs-4 text-gray-600" />,
    link: `/dashboard/exports`,
  },
];

export default Sidebar;
