/* eslint-disable react/react-in-jsx-scope */
import { QueryResponseProvider } from './core/QueryResponseProvider';
import { QueryRequestProvider } from './core/QueryRequestProvider';
import { ListViewProvider, useListView } from './core/ListViewProvider';
import { ExportHeader } from './components/header/Header';
import { ExportTable } from './components/table/ExportTable';

const ExportPage = () => {
  const { itemIdForUpdate } = useListView();

  return (
    <>
      <ExportHeader />
      <ExportTable />
    </>
  );
};

const Export = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
          <ListViewProvider>
            <ExportPage />
          </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

export { Export };
