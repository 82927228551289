/* eslint-disable curly */
/* eslint-disable react/react-in-jsx-scope */
import { useState, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { Button, Drawer, Box, TextField, CircularProgress, Snackbar, Alert } from '@mui/material';
import { drawerBody, drawerTextArea } from './CreateStyles.sx';
import ClearIcon from '@mui/icons-material/Clear';
import CustomInput from 'app/components/CustomInput';
import FieldError from 'app/components/FieldError';
import { useListView } from '../../core/ListViewProvider';
import { useMyProperty } from '../../core/useMyProperty';
import { useMyPropertyListQueryResponse } from '../../core/MyPropertyListQueryResponse';
import { AsyncSelectPagination, contactOption } from 'app/components/CustomSelect/AsyncSelectPagination';
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete';
const Create = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  const {
    itemIdForUpdate,
    itemIsUpdate,
    setItemIdForUpdate,
    setItemIsUpdate,
    setIsOpenCreateDrawer,
    isOpenCreateDrawer,
  } = useListView();

  const {
    control,
    register,
    errors,
    getValues,
    reset,
    onSubmit,
    createMyPropertyMutation,
    getMyPropertyByIdQuery,
    loadContactsOptions,
    onPlaceChanged,
    loadPropertyTypeOptions,
    loadMyPropertyListOptions,
  } = useMyProperty({
    onCloseCreate: onCloseDrawer,
    onOpenScankbar: setIsSnackbarOpen,
    itemIdForUpdate,
    itemIsUpdate,
  });

  function onCloseDrawer() {
    setItemIdForUpdate(undefined);
    reset();
    setItemIsUpdate(false);
    setIsOpenCreateDrawer(false);
  }

  let renderCreate: ReactNode;
  if (getMyPropertyByIdQuery.isFetching)
    renderCreate = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <CircularProgress size={40} />
        </div>
      </Box>
    );
  else
    renderCreate = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="fs-3 fw-bold text-black">{itemIsUpdate ? 'Edit' : 'Create New'} Property</h2>
          <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={onCloseDrawer}>
            <ClearIcon />
          </Button>
        </div>
        <form className="row mt-5" onSubmit={onSubmit} data-isedit={itemIsUpdate}>
          <div className="col-12 mb-2">
            <CustomInput
              label="Owner name"
              id="ownerName"
              type="text"
              error={errors.ownerName}
              rest={{
                ...register('ownerName', {
                  value: getValues('ownerName') || '',
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <CustomInput
              label="Title"
              id="title"
              type="text"
              error={errors.title}
              rest={{
                ...register('title', {
                  value: getValues('title') || '',
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Property type"
              control={control}
              rest={{
                ...register('typeId'),
              }}
              error={errors.typeId}
              loadOptions={loadPropertyTypeOptions}
              isSearchable={false}
            />
          </div>

          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Group"
              control={control}
              rest={{
                ...register('groupId'),
              }}
              error={errors.groupId}
              loadOptions={loadMyPropertyListOptions}
              isSearchable={false}
            />
          </div>

          <div className="col-12 mb-4">
            <Autocomplete
              id="address"
              label="Address"
              onPlaceSelect={onPlaceChanged}
              error={errors.address?.fullAddress}
              rest={{
                ...register('address.fullAddress', {
                  value: getValues('address.fullAddress') || '',
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <CustomInput
              label="City"
              id="address"
              type="text"
              error={errors.address?.city}
              rest={{
                ...register('address.city', {
                  value: getValues('address.city') || '',
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <CustomInput
              label="ZIP code"
              id="address"
              type="text"
              error={errors.address?.postalCode}
              rest={{
                ...register('address.postalCode', {
                  value: getValues('address.postalCode') || '',
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Select Contact"
              control={control}
              rest={{
                ...register('myContactId'),
              }}
              optionComponent={contactOption}
              error={errors.myContactId}
              loadOptions={loadContactsOptions}
            />
          </div>

          <div className="col-12 mb-4">
            <Controller
              control={control}
              name="note"
              render={({ field: { onBlur, onChange, value } }) => (
                <>
                  <TextField
                    multiline={true}
                    sx={drawerTextArea}
                    placeholder="Add a note"
                    label="Add a note"
                    id="note"
                    value={value}
                    error={errors.note !== undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  <FieldError error={errors.note} />
                </>
              )}
            />
          </div>
          <div className="col-12">
            <Button
              type="submit"
              className="w-100"
              size="large"
              disabled={createMyPropertyMutation.isLoading || getMyPropertyByIdQuery.isLoading}
              startIcon={
                (createMyPropertyMutation.isLoading || getMyPropertyByIdQuery.isLoading) && (
                  <CircularProgress size={20} />
                )
              }
            >
              {itemIsUpdate ? 'Edit' : 'Create New'} Property
            </Button>
          </div>
        </form>
      </Box>
    );

  return (
    <>
      <Button size="large" variant="text" onClick={() => setIsOpenCreateDrawer(true)}>
        Create new property +
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={createMyPropertyMutation.isSuccess || getMyPropertyByIdQuery.isSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {createMyPropertyMutation.isError || getMyPropertyByIdQuery.isError
            ? (createMyPropertyMutation.error as any)?.message || (getMyPropertyByIdQuery.error as any)?.message
            : `Your contact ${itemIsUpdate ? 'updated' : 'created'} successlufy!`}
        </Alert>
      </Snackbar>
      <Drawer anchor="right" variant="temporary" open={isOpenCreateDrawer} onClose={onCloseDrawer}>
        {renderCreate}
      </Drawer>
    </>
  );
};

export { Create };
