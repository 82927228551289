import { Box, IconButton, styled } from '@mui/material';

export const NotificationContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '20px',
  right: '20px',
  zIndex: 2000,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  background: '#FFFFFF',
  borderRadius: '12px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  width: 'fit-content',
  maxWidth: '400px',
  animation: 'slideIn 0.3s ease-out',
  '@keyframes slideIn': {
    from: {
      transform: 'translateX(100%)',
      opacity: 0,
    },
    to: {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  borderRadius: '8px',
  backgroundColor: '#1a2236',
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  marginLeft: 'auto',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

export const ContentWrapper = styled(Box)({
  flex: 1,
  minWidth: 0,
});
