/* eslint-disable curly */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { ReactNode } from 'react';
import { Button, Drawer, Box, TextField, CircularProgress } from '@mui/material';
import { drawerBody, drawerTextArea } from './DetailsStyle.sx';
import ClearIcon from '@mui/icons-material/Clear';
import { useListView } from '../../core/ListViewProvider';
import { useQuery } from 'react-query';
import { getDealPiplineById } from '../../core/_requests';
import { isNotEmpty } from 'app/constants/helpers';
import { QUERIES } from 'app/constants/consts';
import logger from 'app/utils/logger';
import { Setting4, User, Location, Building, TagRight, Map, SmsTracking, Stage, ThreeSquare, FourSquare } from 'app/components/SvgIcons';
import SvgCalendar from 'app/components/SvgIcons/Calendar';
import { formatRelativeTime } from '../../core/dateHelper';
import { Money } from '@mui/icons-material';
import SvgRepeat from 'app/components/SvgIcons/Repeat';
import SvgFlag from 'app/components/SvgIcons/Flag';
import RelatedPropertyCard from '../../core/RelatedProperty';

const Details = () => {
  const {
    itemIdForUpdate,
    setItemIdForUpdate,
    setItemIsUpdate,
    setIsOpenDetails,
    isOpenDetails,
    setIsOpenCreateDrawer,
  } = useListView();

  const onClose = () => {
    setItemIdForUpdate(undefined);
    setIsOpenDetails(false);
  };

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
  const {
    isLoading,
    data: property,
    error,
  } = useQuery(
    `${QUERIES.MYPROPERTY_LIST}-property-${itemIdForUpdate}`,
    () => {
      return getDealPiplineById(itemIdForUpdate);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        onClose();
        logger.error(err);
      },
    }
  );

  let renderDetails: ReactNode;
  if (!isLoading && !error && property)
    renderDetails = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-end mb-4">
          <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={onClose}>
            <ClearIcon />
          </Button>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center">
            <h3 className="fw-bold fs-5 text-black p-0 m-0 ms-2">{property?.name}</h3>
          </div>
          <div className="d-flex align-items-center">
            <Button
              variant="text"
              className="p-0 m-0 ms-1 text-end"
              startIcon={<Setting4 className="fs-5" />}
              onClick={() => {
                setItemIsUpdate(true);
                setIsOpenCreateDrawer(true);
              }}
            >
              Edit
            </Button>
          </div>
        </div>



        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Stage className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">State</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.stage.name}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Location className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Address</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.address.fullAddress}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <User className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Deal lead</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.lead?.name}</span>
        </div>

        <div className="d-flex justify-content-between py-3">
          <div className="d-flex align-items-center col-6">
            <ThreeSquare className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Property Type</span>
          </div>
          <span className="fs-6 text-black ps-2 col-6 text-end">{property?.propertyType?.name}</span>
        </div>

        <div className="d-flex align-items-start justify-content-between py-3">
          <div className="d-flex align-items-center">
            <FourSquare className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Deal type</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.dealType}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <SvgCalendar className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Dasys in pipline</span>
          </div>
          <span className="fs-6 text-black ps-1">{formatRelativeTime(new Date(property.createdTime))}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <SvgCalendar className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Contract Date</span>
          </div>
          <span className="fs-6 text-black ps-1">{property.contractDate}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <SvgCalendar className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">EMD Deadline</span>
          </div>
          <span className="fs-6 text-black ps-1">{property.emdDeadline}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <SvgCalendar className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Due Deligence</span>
          </div>
          <span className="fs-6 text-black ps-1">{property.dueiligencePeriod}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <SvgCalendar className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Closing Date</span>
          </div>
          <span className="fs-6 text-black ps-1">{property.closingDate}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Money className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Contract Price</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.contractPrice}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Money className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">EMD</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.emd}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Money className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Expenses</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.expenses}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Money className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Market Value</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.marketValue}</span>
        </div>

        <div className="d-flex align-items-start justify-content-between py-3">
          <div className="d-flex align-items-center">
            <SvgRepeat className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Transaction Type</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.transactionType}</span>
        </div>

        <div className="d-flex align-items-start justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Money className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Pontential ROI</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.pontentialROI}</span>
        </div>

        <div className="d-flex align-items-start justify-content-between py-3">
          <div className="d-flex align-items-center">
            <SvgFlag className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Pontential ROI Overrided</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.pontentialROIOverride ? 'YES' : 'NO'}</span>
        </div>

        <div className="w-100 my-3">
          <TextField
            multiline={true}
            sx={drawerTextArea}
            placeholder="Add a note"
            label="Note"
            disabled
            value={property?.notes}
          />
        </div>

        <div className="w-100 my-3">
          {property.relatedListing &&
            <RelatedPropertyCard
              image={property?.relatedListing.image}
              address={property?.relatedListing.address.fullAddress}
              propertyType={property?.relatedListing.propertyType}
            />
          }

          {property?.relatedProperty &&
            <>
              <div>
                <span className="fs-6 text-black p-0 m-0 ms-2">Related property</span>
              </div>
              <RelatedPropertyCard
                image={property?.relatedProperty.image}
                address={property?.relatedProperty.address.fullAddress}
                propertyType={property?.relatedProperty.propertyType}
              />
            </>

          }
        </div>
      </Box>
    );
  else
    renderDetails = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <CircularProgress />
        </div>
      </Box>
    );

  return (
    <>
      <Drawer anchor="right" variant="temporary" open={isOpenDetails} onClose={onClose}>
        {renderDetails}
      </Drawer>
    </>
  );
};

export { Details };
