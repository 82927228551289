/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { FC } from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material';
import defaultImage from 'assets/default-image.png';
import { getImageUrl } from '../../../core/_requests';


type Props = {
  title?: string;
  className?: string;
};

type ImageCellProps = {
  image?: string;
  address?: string;
};

const DealPipelineCustomCell: FC<Props> = ({ title, className }) => (
  <div className={clsx(className && className)}>
    <span>{title}</span>
  </div>
);


const DealPipelineImageCell: FC<ImageCellProps> = ({ image, address }) => (
  <ListingImage
    src={getImageUrl(image)}
    alt={address}
    onError={(e) => {
      e.currentTarget.src = defaultImage;
    }}
  />
);

export const ListingImage = styled('img')({
  width: 96,
  height: 80,
  objectFit: 'cover',
  borderRadius: 4,
});

export { DealPipelineCustomCell,DealPipelineImageCell };
