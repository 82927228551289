/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { Button, Drawer, Box, Divider, CircularProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { drawerBody } from './ActionsStyle.sx';
import ClearIcon from '@mui/icons-material/Clear';
import { useListView } from '../../core/ListViewProvider';
import { useMutation, useQueryClient } from 'react-query';
import { deleteSelectedExports } from '../../core/_requests';
import { QUERIES } from 'app/constants/consts';
import { useQueryResponse } from '../../core/QueryResponseProvider';
const Actions = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const { selected, clearSelected } = useListView();

  const { query } = useQueryResponse();
  const queryClient = useQueryClient();
  const deleteSelectedItems = useMutation(() => deleteSelectedExports(selected), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EPORT_LIST}-${query}`]);
      clearSelected();
    },
  });

  return (
    <>
      <Button size="large" startIcon={<MenuIcon />} onClick={() => setIsOpenDrawer(true)}>
        Actions
      </Button>
      <Drawer anchor="right" variant="temporary" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
        <Box sx={drawerBody}>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h2 className="fs-3 fw-bold text-black">Actions</h2>
            <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={() => setIsOpenDrawer(false)}>
              <ClearIcon />
            </Button>
          </div>
          <div className="row">
            <div className="col-12 mb-5">
              <Button
                variant="text"
                size="large"
                className="text-black fw-bold text-start p-0 w-100 d-flex justify-content-start mb-5 fs-5"
                onClick={async () => {
                  await deleteSelectedItems.mutateAsync();
                  setIsOpenDrawer(false);
                }}
                disabled={deleteSelectedItems.isLoading || selected.length === 0} 
                startIcon={deleteSelectedItems.isLoading && <CircularProgress size={20} />}
                sx={{
                  opacity: (deleteSelectedItems.isLoading || selected.length === 0) ? 0.5 : 1,
                  color: (deleteSelectedItems.isLoading || selected.length === 0) ? '#a0a0a0' : 'black',
                }}
              >
                Delete Exports
              </Button>
              <Divider component="div" />
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export { Actions };
