import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';

// Styles
import 'flatpickr/dist/themes/material_green.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/sass/style.scss';
import './assets/sass/plugins.scss';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import './index.css';
import logger from './app/utils/logger';

// Service Worker Registration
const registerServiceWorker = async () => {
  if (!('serviceWorker' in navigator)) {
    logger.error('Service worker not supported');
    return;
  }

  try {
    // Unregister any existing service workers
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      await registration.unregister();
    }

    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
      scope: '/',
    });

    logger.log('ServiceWorker registration successful with scope:', registration.scope);

    registration.addEventListener('activate', (event) => {
      logger.log('Service worker activated');
    });

    registration.addEventListener('install', (event) => {
      logger.log('Service worker installed');
    });

    registration.addEventListener('error', (error) => {
      logger.error('Service worker error:', error);
    });

    // Wait for the service worker to be ready
    await navigator.serviceWorker.ready;
    logger.log('Service worker is ready');
  } catch (err) {
    logger.error('ServiceWorker registration failed:', err);
  }
};

// Register service worker before rendering the app
if (process.env.NODE_ENV === 'production' || window.location.hostname === 'localhost') {
  registerServiceWorker();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

reportWebVitals();
