/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { Response } from 'app/constants/models';
export type ExportHistory = {
  id: number;
  name: string ;
  fileUrl: string;
  createdTime: string;
  finishedTime: string;
  type: ExportType;
};


export enum ExportType {
  Contact = 1,
  Property,
  Listing,
  Activity
}
export type MyPropertyQueryResponse = Response<Array<ExportHistory>>;

export const initialMyProperty: ExportHistory = {
  id: 1,
  name:'',
  fileUrl:'',
  createdTime:'',
  finishedTime:'',
  type: ExportType.Activity
};


