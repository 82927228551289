import { httpClient } from 'app/api/http/http-client';
import { MyPropertyQueryResponse, ExportHistory } from './_models';
import axios from 'axios';

const getMyProperties = (query: string): Promise<MyPropertyQueryResponse> => {
  return httpClient.get(`/ExportHistory?${query}`);
};


const deleteSelectedExports = (ids: number[]): Promise<void> => {

  return httpClient.delete(`/ExportHistory`, { data: ids })
};





export {
  getMyProperties,
  deleteSelectedExports,
};
