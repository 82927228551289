import * as React from 'react';
import type { SVGProps } from 'react';
const Money = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M11.3359 13.6666H4.66927C2.66927 13.6666 1.33594 12.6666 1.33594 10.3333V5.66659C1.33594 3.33325 2.66927 2.33325 4.66927 2.33325H11.3359C13.3359 2.33325 14.6693 3.33325 14.6693 5.66659V10.3333C14.6693 12.6666 13.3359 13.6666 11.3359 13.6666Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
        />
        <path
            d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
        />
        <path
            d="M3.66406 6.33325V9.66659"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
        />
        <path
            d="M12.3359 6.33325V9.66659"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
        />
    </svg>
);
export default Money;