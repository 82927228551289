/* eslint-disable curly */
/* eslint-disable react/react-in-jsx-scope */
import { useState, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { Button, Drawer, Box, TextField, CircularProgress, Snackbar, Alert } from '@mui/material';
import { drawerBody, drawerTextArea } from './CreateStyles.sx';
import ClearIcon from '@mui/icons-material/Clear';
import CustomInput from 'app/components/CustomInput';
import FieldError from 'app/components/FieldError';
import { useListView } from '../../core/ListViewProvider';
import { useContact } from '../../core/useContact';
import { useContactListQueryResponse } from '../../core/ContactListQueryResponse';
import CreatableSelect from 'app/components/CustomSelect/CreatableSelect';
import {
  AsyncSelectPagination,
  dealPiplineOption,
  propertyOption,
} from 'app/components/CustomSelect/AsyncSelectPagination';
import { useQuery } from 'react-query';
import { getContactListTags } from '../../core/_requests';

const Create = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const {
    itemIdForUpdate,
    itemIsUpdate,
    setItemIdForUpdate,
    setItemIsUpdate,
    setIsOpenCreateDrawer,
    isOpenCreateDrawer,
  } = useListView();

  const contactListData = useContactListQueryResponse();
  const { data: tags } = useQuery('contact-tags', async () => {
    const tagsData = await getContactListTags();
    return tagsData.map((tag) => ({
      value: tag,
      label: tag,
    }));
  });

  const {
    control,
    register,
    errors,
    getValues,
    reset,
    onSubmit,
    createContactMutation,
    getContactByIdQuery,
    loadContactOptions,
    loadPropertyOptions,
    loadDealOptions,
  } = useContact({
    onCloseCreate: onCloseDrawer,
    onOpenScankbar: setIsSnackbarOpen,
    itemIdForUpdate,
    itemIsUpdate,
  });

  function onCloseDrawer() {
    setItemIdForUpdate(undefined);
    reset();
    setItemIsUpdate(false);
    setIsOpenCreateDrawer(false);
  }

  let renderCreate: ReactNode;
  if (getContactByIdQuery.isFetching)
    renderCreate = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <CircularProgress size={40} />
        </div>
      </Box>
    );
  else
    renderCreate = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="fs-3 fw-bold text-black">{itemIsUpdate ? 'Edit' : 'Create New'} Contact</h2>
          <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={onCloseDrawer}>
            <ClearIcon />
          </Button>
        </div>
        <form className="row mt-5" onSubmit={onSubmit} data-isedit={itemIsUpdate}>
          <div className="col-12 mb-2">
            <CustomInput
              label="Name"
              id="name"
              type="text"
              error={errors.name}
              rest={{
                ...register('name', {
                  value: getValues('name') || '',
                }),
              }}
            />
          </div>
          <div className="col-12 mb-2">
            <CustomInput
              label="Lastname"
              id="lastName"
              type="text"
              error={errors.lastName}
              rest={{
                ...register('lastName', {
                  value: getValues('lastName') || '',
                }),
              }}
            />
          </div>
          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Contact list"
              control={control}
              rest={{
                ...register('groupId'),
              }}
              options={contactListData.response}
              error={errors.groupId}
              loadOptions={loadContactOptions}
              isSearchable={false}
            />
          </div>
          <div className="col-12 mb-2">
            {/* <CustomInput label="Tags" /> */}
            <CreatableSelect
              label="Tags"
              control={control}
              error={errors.tags}
              rest={{
                ...register('tags'),
              }}
              options={tags}
              isMulti
            />
          </div>
          <div className="col-12 mb-2">
            <CustomInput
              label="Phone Number"
              id="phoneNumber"
              type="text"
              error={errors.phoneNumber}
              rest={{
                ...register('phoneNumber', {
                  value: getValues('phoneNumber') || '',
                }),
              }}
            />
          </div>
          <div className="col-12 mb-2">
            <CustomInput
              label="Email Address"
              id="email"
              type="text"
              error={errors.email}
              rest={{
                ...register('email', {
                  value: getValues('email') || '',
                }),
              }}
            />
          </div>
          <div className="col-12 mb-2">
            <CustomInput
              label="Address"
              id="address"
              type="text"
              error={errors.address}
              rest={{
                ...register('address', {
                  value: getValues('address') || '',
                }),
              }}
            />
          </div>
          <div className="col-12 mb-2">
            <CustomInput
              label="Company Name"
              id="companyName"
              type="text"
              error={errors.companyName}
              rest={{
                ...register('companyName', {
                  value: getValues('companyName') || '',
                }),
              }}
            />
          </div>
          <div className="col-12 mb-2">
            <CustomInput
              label="Title"
              id="title"
              type="text"
              error={errors.title}
              rest={{
                ...register('title', {
                  value: getValues('title') || '',
                }),
              }}
            />
          </div>
          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Select Related Property"
              control={control}
              rest={{
                ...register('properties'),
              }}
              optionComponent={propertyOption}
              error={errors.properties}
              isMulti
              defaultValue={[]}
              loadOptions={loadPropertyOptions}
            />
          </div>
          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Select Deal"
              control={control}
              rest={{
                ...register('deals'),
              }}
              optionComponent={dealPiplineOption}
              error={errors.deals}
              isMulti
              defaultValue={[]}
              loadOptions={loadDealOptions}
            />
          </div>
          <div className="col-12 mb-4">
            <Controller
              control={control}
              name="note"
              render={({ field: { onBlur, onChange, value } }) => (
                <>
                  <TextField
                    multiline={true}
                    sx={drawerTextArea}
                    placeholder="Add a note"
                    label="Add a note"
                    id="note"
                    value={value}
                    error={errors.note !== undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  <FieldError error={errors.note} />
                </>
              )}
            />
          </div>
          <div className="col-12">
            <Button
              type="submit"
              className="w-100"
              size="large"
              disabled={createContactMutation.isLoading || getContactByIdQuery.isLoading}
              startIcon={
                (createContactMutation.isLoading || getContactByIdQuery.isLoading) && <CircularProgress size={20} />
              }
            >
              {itemIsUpdate ? 'Edit' : 'Create New'} Contact
            </Button>
          </div>
        </form>
      </Box>
    );

  return (
    <>
      <Button size="large" variant="text" onClick={() => setIsOpenCreateDrawer(true)}>
        Create new contact +
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={createContactMutation.isSuccess || getContactByIdQuery.isSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {createContactMutation.isError || getContactByIdQuery.isError
            ? (createContactMutation.error as any)?.message || (getContactByIdQuery.error as any)?.message
            : `Your contact ${itemIsUpdate ? 'updated' : 'created'} successlufy!`}
        </Alert>
      </Snackbar>
      <Drawer anchor="right" variant="temporary" open={isOpenCreateDrawer} onClose={onCloseDrawer}>
        {renderCreate}
      </Drawer>
    </>
  );
};

export { Create };
