/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import clsx from 'clsx';
import { FC } from 'react';
import { Row } from 'react-table';
import { DealPipeline } from '../../../core/_models';

type Props = {
  row: Row<DealPipeline>;
};

const DealPiplineRow: FC<Props> = ({ row }) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td {...cell.getCellProps()} className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}>
          {cell.render('Cell')}
        </td>
      );
    })}
  </tr>
);

export { DealPiplineRow };
