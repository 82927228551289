/* eslint-disable padding-line-between-statements */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext } from 'react';
import { useQuery } from 'react-query';
import { WithChildren, initialQueryResponse } from 'app/constants/models';
import { createResponseContext } from 'app/constants/helpers';
import { QUERIES } from 'app/constants/consts';
import {  getStages } from './_requests';
import { Stages } from './_models';

const QueryResponseContext = createResponseContext<Stages>(initialQueryResponse);
const StageQueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.DEAL_PIPELINE_STAGE}`,
    () => {
      return getStages();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response }}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useStageQueryResponse = () => useContext(QueryResponseContext);

export { StageQueryResponseProvider, useStageQueryResponse };
