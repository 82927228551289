import React from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface SnackbarProps {
  open: boolean;
  message: string;
  onClose: (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => void;
  autoHideDuration?: number;
  anchorOrigin?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' };
  style?: React.CSSProperties;
}

const CustomSnackbar: React.FC<SnackbarProps> = ({
  open,
  message,
  onClose,
  autoHideDuration = 5000,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  style = {},
}) => {
  const slideTransition = (props: any) => <Slide {...props} direction="up" />;

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={message}
      anchorOrigin={anchorOrigin}
      TransitionComponent={slideTransition}
      style={style}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.5 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

export default CustomSnackbar;
