import React, { useState, useEffect } from 'react';
import { Bell, X } from 'lucide-react';
import { onMessage } from 'firebase/messaging';
import logger from '../../utils/logger';
import { requestNotificationPermission } from '../../../services/NotificationService';
import { INotification } from '../../../types/notification';
import { messaging } from '../../../config/firebase-config';
import { CloseButton, ContentWrapper, IconWrapper, NotificationContainer } from './push-notification.sx';
import { Typography } from '@mui/material';

const PushNotificationComponent: React.FC = () => {
  const [notification, setNotification] = useState<INotification>({ title: '', body: '' });
  const [open, setOpen] = useState<boolean>(false);
  const [fcmToken, setFcmToken] = useState<string>('');

  useEffect(() => {
    const setupNotifications = async () => {
      try {
        // Request permission and get token
        const token = await requestNotificationPermission();
        setFcmToken(token);

        // Setup foreground message listener
        const unsubscribe = onMessage(messaging, (payload) => {
          setNotification({
            title: payload?.notification?.title || '',
            body: payload?.notification?.body || '',
          });
          logger.log('Message received. ', payload);
          setOpen(true);
        });

        return () => {
          if (unsubscribe) {
            unsubscribe();
          }
        };
      } catch (error) {
        logger.error('Failed to setup notifications:', error);
        logger.error('Failed to setup notifications:', error);
      }
    };

    setupNotifications();
  }, []);

  if (!open) {
    return null;
  }

  return (
    <NotificationContainer>
      <IconWrapper>
        <Bell size={24} color="white" />
      </IconWrapper>

      <ContentWrapper>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            color: '#1a2236',
            mb: 0.5,
          }}
        >
          {notification.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: '#6B7280',
            fontSize: '14px',
          }}
        >
          {notification.body}
        </Typography>
      </ContentWrapper>

      <CloseButton onClick={() => setOpen(false)} size="small" aria-label="close notification">
        <X size={20} />
      </CloseButton>
    </NotificationContainer>
  );
};

export default PushNotificationComponent;
