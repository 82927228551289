/* eslint-disable padding-line-between-statements */
import { httpClient } from 'app/api/http/http-client';
import { ContactsQueryResponse, Contact, ContactListQueryResponse, ContactList } from './_models';
import axios from 'axios';

const getContacts = (query: string): Promise<ContactsQueryResponse> => {
  return httpClient.get(`/UserContact/byfilter?${query}`);
};

const getContactsMinimal = (query: string): Promise<ContactsQueryResponse> => {
  return httpClient.get(`/UserContact/minimal?${query}`);
};

const getContactList = (): Promise<ContactListQueryResponse> => {
  return httpClient.get('/UserContactList');
};

const getContactListTags = (): Promise<string[]> => {
  return httpClient.get('/UserContactList/GetTags');
};

const createExport = () => {
  return httpClient.post('/UserContact/Export');
};

const getTags = (groupIds: number[]): Promise<string[]> => {
  return httpClient.post('/UserTags', groupIds);
};

const getContactById = (id: number): Promise<Contact> => {
  return httpClient.get(`/UserContact/${id}`);
};

const createContact = (body: Contact): Promise<boolean> => {
  return httpClient.post('/UserContact', body);
};

const createContactList = (body: ContactList): Promise<boolean> => {
  return httpClient.post('/UserContactList', body);
};

const updateContact = (body: Contact): Promise<boolean> => {
  return httpClient.put('/UserContact', body);
};

const updateContactList = (id: number, name: string): Promise<boolean> => {
  return httpClient.put(`/UserContactList/${id}/name`, name);
};

const deleteSelectedContacts = (ids: number[]): Promise<void> => {
  const requests = ids.map((id) => httpClient.delete(`/UserContact/${id}`));
  return axios.all(requests).then(() => {});
};

const deleteContactListItem = (id: number): Promise<void> => {
  return httpClient.delete(`/UserContactList/${id}/3`);
};

const sendSMS = (body: FormData) => {
  return httpClient.post('/UserContact/sms', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const sendEmail = (body: FormData) => {
  return httpClient.post('/UserContact/email', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const moveTo = (body: any) => {
  return httpClient.put('/UserContact/list', body);
};

export {
  getContacts,
  getContactList,
  getContactById,
  createContact,
  createContactList,
  updateContact,
  updateContactList,
  deleteSelectedContacts,
  deleteContactListItem,
  sendSMS,
  sendEmail,
  moveTo,
  getTags,
  createExport,
  getContactsMinimal,
  getContactListTags,
};
