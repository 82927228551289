import React, { ChangeEvent, useState } from 'react';
import MyListings from './components/my-listing';
import { Badge, Box, Button, Menu, MenuItem } from '@mui/material';
import { Add } from '@mui/icons-material';
import CustomInput from 'app/components/CustomInput';
import { Link, useNavigate } from 'react-router-dom';
import { Notification } from '../../../components/SvgIcons';
import { getActivityPendingCount } from './core/_requests';
import { useQuery } from 'react-query';
import { QUERIES } from '../../../constants/consts';
import { styled } from '@mui/material/styles';

const MyListingsPage = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data: pendingCount = 0 } = useQuery(`${QUERIES.ACTIVITIES}-pending`, getActivityPendingCount, {
    refetchInterval: 30000,
  });

  const handleRedirect = (to: string) => {
    navigate(`/add-listing/${to}/property-information`);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 }}>
        <div className="col-12 col-md-4">
          <CustomInput
            label="Search by address"
            value={searchQuery}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
          />
        </div>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={(event: React.MouseEvent<any>) => {
              setAnchorEl(event.currentTarget);
              setOpenMenu(true);
            }}
          >
            Create new Listing
          </Button>
          <Button
            variant="contained"
            component={Link}
            to="requests"
            startIcon={
              <RequestsBadge badgeContent={pendingCount > 0 ? pendingCount : undefined} color="error">
                <Notification />
              </RequestsBadge>
            }
          >
            Requests
          </Button>
          <Menu
            sx={{ '& .MuiPaper-root': { width: '294px', mt: '5px' } }}
            id="basic-menu"
            open={openMenu}
            anchorEl={anchorEl}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            onClose={() => setOpenMenu(false)}
          >
            <MenuItem onClick={() => handleRedirect('commercial')}>Commercial</MenuItem>
            <MenuItem onClick={() => handleRedirect('residential')}>Residential</MenuItem>
          </Menu>
        </Box>
      </Box>
      <MyListings searchQuery={searchQuery} />
    </>
  );
};

export { MyListingsPage };

const RequestsBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
