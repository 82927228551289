/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { QUERIES } from 'app/constants/consts';
import Modal from 'react-bootstrap/Modal';
import { useQueryClient, useMutation } from 'react-query';
import { useStageQueryResponse } from '../../core/StageQueryResponse';
import { deleteStage } from '../../core/_requests';

type Props = {
  id: number | null;
  show: boolean;
  handleClose: () => void;
};

const DeleteModal: FC<Props> = ({ id, show, handleClose }) => {
  const queryClient = useQueryClient();
  const { refetch } = useStageQueryResponse();
  const deleteItem = useMutation(() => deleteStage(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.MYPROPERTY_LIST}-DELETE`]);
      refetch();
      handleClose();
    },
  });

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete property list</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure?</Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button
          className="bg-danger-reminder d-flex justify-content-start text-white"
          onClick={async () => await deleteItem.mutate()}
          disabled={deleteItem.isLoading}
          startIcon={deleteItem.isLoading && <CircularProgress size={20} color="inherit" />}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { DeleteModal };
