import React from "react";
import { Card, CardContent, Typography, Box, CardMedia } from "@mui/material";

// Define the props type
interface RelatedPropertyProps {
    address: string;
    name: string;
    propertyType: string;
    group: string;
}

// Functional Component with Props
const RelatedPropertyWithoutImageCard: React.FC<RelatedPropertyProps> = ({ address, propertyType, name, group }) => {
    return (
        <div className="border rounded mb-2">
            <div className="d-flex flex-column">
                <Card sx={{ display: "flex", alignItems: "center", maxWidth: 600, borderRadius: 3 }}>
                    <Box sx={{ flex: 1 }}>
                        <CardContent>
                            <h4 className="fs-6 text-body-secondary">{name} ({group})</h4>
                            <Typography variant="subtitle2" color="textPrimary" sx={{ marginTop: 1 }}>
                                Address: {address}
                            </Typography>
                            <small className="text-body-tertiary">  {propertyType}</small>
                        </CardContent>
                    </Box>
                </Card>
            </div>
        </div>
    );
};

export default RelatedPropertyWithoutImageCard;
