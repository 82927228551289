import React, { ChangeEvent, useState } from 'react';
import { Box, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import CustomInput from '../CustomInput';
import DatePicker from 'react-datepicker';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import format from 'date-fns/format';

interface Props {
  id?: string;
  label?: string;
  error?: any;
  rest?: any;
  value:string;
}


const DatePickerInput = (props: Props) => {
  const {
    id,
    label,
    error,
    rest,
    value,
  } = props;

  
const [focused, setFocused] = useState(false);
const [closingDate, setClosingDate] = useState(null);
const [closingResDate, setClosingResDate] = useState(value);

const onFocus = () => {
  setFocused(true);
};

const handleBlur = () => {
  setFocused(false);
};

const handleClosingDate = (closingDate_: any) => {
  setClosingDate(closingDate_);
  setClosingResDate(format(closingDate_, 'MM-dd-yyyy'));
};

  return (
    <FormControl sx={{ height: '69px', width: '100%' }}>
      <CustomInput
        id={id}
        label={label}
        type='text'
        value={closingResDate}
        onFocus={onFocus}
        error={error}
        {...rest}
        endAdornment={<CalendarMonthOutlinedIcon />}
      />

      {focused && <Box sx={{ position: 'absolute', zIndex: 2, top: '50px' }}>
        <DatePicker
          selected={closingDate}
          onChange={(date) => handleClosingDate(date)}
          onSelect={(date) => handleClosingDate(date)}
          inline
          shouldCloseOnSelect={true}
          onClickOutside={handleBlur}
        />
      </Box>}
    </FormControl>
  );
};

export default DatePickerInput;