/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { FC } from 'react';
import { Button } from '@mui/material';
import { useListView } from '../../../core/ListViewProvider';

type Props = {
  id: number;
};

const DealPipelineActionCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate, setIsOpenDetails } = useListView();
  const onOpenDetailsModal = () => {
    setItemIdForUpdate(id);
    setIsOpenDetails(true);
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Button className="bg-warning p-3 text-black fs-7" onClick={onOpenDetailsModal}>
        Details
      </Button>
    </div>
  );
};



export { DealPipelineActionCell  };
