/* eslint-disable react/react-in-jsx-scope */
import { Column } from 'react-table';
import { MyPropertySelectionCell } from './MyPropertySelectionCell';
import { MyPropertySelectionHeader } from './MyPropertySelectionHeader';
import { MyPropertyCustomHeader } from './MyPropertyCustomHeader';
import { MyPropertyCustomCell } from './MyPropertyCustomCell';
import { ExportActionCell } from './ExportActionCell';
import { ExportHistory, ExportType } from '../../../core/_models';

const contactsColumns: ReadonlyArray<Column<ExportHistory>> = [
  {
    Header: (props) => <MyPropertySelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <MyPropertySelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <MyPropertyCustomHeader tableProps={props} title="Name" className="min-w-150px text-center" />
    ),
    id: 'Name',
    Cell: ({ ...props }) => (
      <MyPropertyCustomCell title={props.data[props.row.index]?.name} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <MyPropertyCustomHeader tableProps={props} title="Date" className="min-w-150px text-center" />
    ),
    id: 'Date',
    Cell: ({ ...props }) => (
      <MyPropertyCustomCell title={props.data[props.row.index]?.createdTime} className="text-center" />
    ),
  },
  {
    Header: (props) => <MyPropertyCustomHeader tableProps={props} title="Type" className="min-w-150px text-center" />,
    id: 'Type',
    Cell: ({ ...props }) => <MyPropertyCustomCell title={ExportType[props.data[props.row.index]?.type]} className="text-center" />,
  },
  {
    Header: (props) => (
      <MyPropertyCustomHeader tableProps={props} title="Actions" className="min-w-150px text-center" />
    ),
    id: 'Actions',
    Cell: ({ ...props }) => <ExportActionCell name={props.data[props.row.index].name} url={props.data[props.row.index].fileUrl} />,
  },
];

export { contactsColumns };
