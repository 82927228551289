export enum NotificationEnabledType {
  None = 0,
  Push = 1,
  Email = 2
}

export interface EnabledTypeState {
  enabledType: NotificationEnabledType;
  isEnabled: boolean;
}

export interface NotificationSettingType {
  id: number;
  name: string;
  description: string;
  enabledTypes: EnabledTypeState[];
}

export type NotificationSettings = NotificationSettingType[];