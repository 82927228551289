import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

export const firebaseConfig = {
  apiKey: "AIzaSyCV0E0N0c1g2UUgLjavty4UaAJ4E8bar6k",
  authDomain: "urexc-13864.firebaseapp.com",
  projectId: "urexc-13864",
  storageBucket: "urexc-13864.firebasestorage.app",
  messagingSenderId: "447064578428",
  appId: "1:447064578428:web:286cd65e508a0a5935ecf9",
  measurementId: "G-XXJSRBQY68"
};


const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };