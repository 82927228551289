import React from 'react';
import { Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { User, Direct, Call } from 'app/components/SvgIcons';

import { UserInfo } from '../../listings/core/_models';
import {
  DrawerContent,
  HeaderContainer,
  InfoContainer,
  InfoLabel,
  StyledDrawer,
  UserContainer,
} from './lead-activity-page.sx';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userInfo: UserInfo;
}

const Details = ({ isOpen, onClose, userInfo }: Props) => {
  return (
    <StyledDrawer anchor="right" variant="temporary" open={isOpen} onClose={onClose}>
      <DrawerContent>
        <HeaderContainer>
          <Button
            sx={{
              minWidth: '40px',
              padding: '8px',
              borderRadius: '8px',
            }}
            variant="outlined"
            size="small"
            onClick={onClose}
          >
            <ClearIcon />
          </Button>
        </HeaderContainer>
        <UserContainer>
          <User className="fs-5" />
          <h3 style={{
            fontWeight: 600,
            fontSize: '1.25rem',
            margin: 0
          }}>
            {userInfo.fullName}
          </h3>
        </UserContainer>

        <InfoContainer>
          <InfoLabel>
            <Direct className="fs-5" />
            <span style={{ fontSize: '1rem' }}>Email</span>
          </InfoLabel>
          <span style={{ fontSize: '1rem' }}>{userInfo.email}</span>
        </InfoContainer>

        <InfoContainer>
          <InfoLabel>
            <Call className="fs-5" />
            <span style={{ fontSize: '1rem' }}>Phone Number</span>
          </InfoLabel>
          <span style={{ fontSize: '1rem' }}>{userInfo.phoneNumber}</span>
        </InfoContainer>
      </DrawerContent>    </StyledDrawer>
  );
};

export default Details;
