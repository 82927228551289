/* eslint-disable padding-line-between-statements */
/* eslint-disable curly */
import * as Yup from 'yup';

export const contactSchema = Yup.object({
  name: Yup.string().required('This field is required'),
  leadId: Yup.object().nullable(),
  propertyTypeId: Yup.object().nullable(),
  stageId: Yup.object().required('This field is required!'),
  dealType: Yup.object().nullable(),
  contractPrice: Yup.number().transform((value, originalValue) => {
    return originalValue === '' ? undefined : value;
  }),
  emd: Yup.number().transform((value, originalValue) => {
    return originalValue === '' ? undefined : value;
  }),
  expenses: Yup.number().transform((value, originalValue) => {
    return originalValue === '' ? undefined : value;
  }),
  marketValue: Yup.number().transform((value, originalValue) => {
    return originalValue === '' ? undefined : value;
  }),
  transactionType: Yup.object().nullable(),
  pontentialROI: Yup.number().transform((value, originalValue) => {
    return originalValue === '' ? undefined : value;
  }),
  pontentialROIOverride: Yup.bool(),
  contractDate: Yup.string().nullable().transform((value, originalValue) => {
    return originalValue === '' ? undefined : value;
  }),
  emdDeadline: Yup.string().nullable().transform((value, originalValue) => {
    return originalValue === '' ? undefined : value;
  }),
  dueiligencePeriod: Yup.string().nullable().transform((value, originalValue) => {
    return originalValue === '' ? undefined : value;
  }),
  closingDate: Yup.string().nullable().transform((value, originalValue) => {
    return originalValue === '' ? undefined : value;
  }),
  note: Yup.string(),
  relatedPropertyId: Yup.number().nullable(),
  relatedListingId: Yup.string().nullable(),
  relatedProp: Yup.object().nullable(),
  address: Yup.object({
    fullAddress: Yup.string().required('This field is required!'),
    state: Yup.string(),
    country: Yup.string(),
    county: Yup.string(),
    city: Yup.string(),
    street: Yup.string(),
    postalCode: Yup.string(),
    longitude: Yup.string(),
    latitude: Yup.string(),
  }),
});

export const moveToSchema = Yup.object({
  listId: Yup.object().required('This field is required'),
});
