/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { Button, Drawer, Box, Divider, CircularProgress, SnackbarCloseReason } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { drawerBody } from './ActionsStyle.sx';
import ClearIcon from '@mui/icons-material/Clear';
import { MoveToList } from '../moveToList/MoveToList';
import CustomSnackbar from 'app/components/CustomSnackbar/CustomSnackbar';
import { createExport } from '../../core/_requests';
const Actions = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenMoveDrawer, setIsOpenMoveDrawer] = useState(false);
  const [openSnackbar, setopenSnackbar] = useState(false);

  const onExport = async () => {
    setIsOpenDrawer(false);
    await createExport();
    setopenSnackbar(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setopenSnackbar(false);
  };

  return (
    <>
      <CustomSnackbar
        open={openSnackbar}
        message="Export prepared after a few minutes"
        onClose={handleClose}
        style={{ bottom: '50px', right: '50px' }}
      />
      <Button size="large" startIcon={<MenuIcon />} onClick={() => setIsOpenDrawer(true)}>
        Actions
      </Button>
      <MoveToList isOpenDrawer={isOpenMoveDrawer} setIsOpenDrawer={setIsOpenMoveDrawer} />
      <Drawer anchor="right" variant="temporary" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
        <Box sx={drawerBody}>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h2 className="fs-3 fw-bold text-black">Actions</h2>
            <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={() => setIsOpenDrawer(false)}>
              <ClearIcon />
            </Button>
          </div>
          <div className="row">
            <div className="col-12 mb-5 mt-5">
              <Button variant="text" size="large" className="text-black fw-bold text-start p-0 w-100 d-block mb-5 fs-5">
                Create Deal
              </Button>
              <Divider component="div" />
            </div>
            <div className="col-12 mb-5">
              <Button variant="text" size="large" className="text-black fw-bold text-start p-0 w-100 d-block mb-5 fs-5">
                Add Reminder
              </Button>
              <Divider component="div" />
            </div>
            <div className="col-12 mb-5">
              <Button
                variant="text"
                size="large"
                className="text-black fw-bold text-start p-0 w-100 d-block mb-5 fs-5"
                onClick={() => setIsOpenMoveDrawer(true)}
              >
                Move to list
              </Button>
              <Divider component="div" />
            </div>
            <div className="col-12 mb-5">
              <Button variant="text" size="large" onClick={onExport} className="text-black fw-bold text-start p-0 w-100 d-block mb-5 fs-5">
                Export
              </Button>
              <Divider component="div" />
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export { Actions };
