/* eslint-disable curly */
/* eslint-disable react/react-in-jsx-scope */
import { useState, ReactNode, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Button, Drawer, Box, TextField, CircularProgress, Snackbar, Alert, FormControl, FormControlLabel, Checkbox, Typography, Link } from '@mui/material';
import { drawerBody, drawerTextArea } from './CreateStyles.sx';
import ClearIcon from '@mui/icons-material/Clear';
import CustomInput from 'app/components/CustomInput';
import FieldError from 'app/components/FieldError';
import { useListView } from '../../core/ListViewProvider';
import { useDealPipeline } from '../../core/useDealPipeline';
import { AsyncSelectPagination } from 'app/components/CustomSelect/AsyncSelectPagination';
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete';
import { BpIcon, BpCheckedIcon } from 'app/components/CustomCheckbox';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DatePicker from 'react-datepicker';
import DatePickerInput from 'app/components/DatePicker';
import TabListDialog from './PropertySelectDialog';
import logger from 'app/utils/logger';
import { number } from 'yup';

const Create = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [isPontentialROIOverride, setisPontentialROIOverride] = useState<boolean>(false);

  const {
    itemIdForUpdate,
    itemIsUpdate,
    setItemIdForUpdate,
    setItemIsUpdate,
    setIsOpenCreateDrawer,
    isOpenCreateDrawer,
  } = useListView();

  const {
    control,
    register,
    errors,
    getValues,
    reset,
    onSubmit,
    createMyPropertyMutation,
    getMyPropertyByIdQuery,
    loadContactsOptions,
    onPlaceChanged,
    loadPropertyTypeOptions,
    loadStage,
    loadProperties,
    loadInvestmentOptions,
    loadTransactionOptions,

  } = useDealPipeline({
    onCloseCreate: onCloseDrawer,
    onOpenScankbar: setIsSnackbarOpen,
    itemIdForUpdate,
    itemIsUpdate,
  });

  function onCloseDrawer() {
    setItemIdForUpdate(undefined);
    reset();
    setItemIsUpdate(false);
    setIsOpenCreateDrawer(false);
  }

  const dialogRef = useRef<{ openDialog: () => void; closeDialog: () => void }>(null);

  const openDialog = () => {
    dialogRef.current?.openDialog();
  };

  const closeDialog = () => {
    dialogRef.current?.closeDialog();
  };

  const handleClik = (id: string, type: number) => {
    logger.log("clicked", id);
    if(type===0 ){
      register("relatedPropertyId",{value: Number(id)});
      register("relatedListingId",{value: null});
    }
    else{
      register("relatedListingId",{value: id});
      register("relatedPropertyId",{value: null});
    }
   
    
    closeDialog();
  }

  let renderCreate: ReactNode;
  if (getMyPropertyByIdQuery.isFetching)
    renderCreate = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <CircularProgress size={40} />
        </div>
      </Box>
    );
  else
    renderCreate = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="fs-3 fw-bold text-black">{itemIsUpdate ? 'Edit' : 'Create New'} Deal Pipeline</h2>
          <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={onCloseDrawer}>
            <ClearIcon />
          </Button>
        </div>
        <form className="row mt-5" onSubmit={onSubmit} data-isedit={itemIsUpdate}>
          <div className="col-12 mb-2">
            <CustomInput
              label="Name"
              id="name"
              type="text"
              error={errors.name}
              rest={{
                ...register('name', {
                  value: getValues('name') || '',
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Stage"
              control={control}
              rest={{
                ...register('stageId'),
              }}
              error={errors.stageId}
              loadOptions={loadStage}
              isSearchable={false}
            />
          </div>

          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Deal lead"
              control={control}
              rest={{
                ...register('leadId'),
              }}
              error={errors.leadId}
              loadOptions={loadContactsOptions}
              isSearchable={false}
            />
          </div>

          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Property type"
              control={control}
              rest={{
                ...register('propertyTypeId'),
              }}
              error={errors.propertyTypeId}
              loadOptions={loadPropertyTypeOptions}
              isSearchable={false}
            />
          </div>


          <div className="col-12 mb-2">
            <Button
              onClick={openDialog}
              className="w-100"
              size="large"
            >
              select
            </Button>
          </div>

          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Deal type"
              control={control}
              rest={{
                ...register('dealType'),
              }}
              error={errors.dealType}
              loadOptions={loadInvestmentOptions}
              isSearchable={false}
            />
          </div>

          <div className="col-12 mb-2">
            <CustomInput
              label="Contract price"
              id="contractPrice"
              type="text"
              error={errors.contractPrice}
              rest={{
                ...register('contractPrice', {
                  value: getValues('contractPrice') || undefined,
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <CustomInput
              label="EMD"
              id="emd"
              type="text"
              error={errors.emd}
              rest={{
                ...register('emd', {
                  value: getValues('emd') || undefined,
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <CustomInput
              label="Expenses"
              id="expenses"
              type="text"
              error={errors.expenses}
              rest={{
                ...register('expenses', {
                  value: getValues('expenses') || undefined,
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <CustomInput
              label="Market value"
              id="marketValue"
              type="text"
              error={errors.marketValue}
              rest={{
                ...register('marketValue', {
                  value: getValues('marketValue') || undefined,
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Transaction type"
              control={control}
              rest={{
                ...register('transactionType'),
              }}
              error={errors.transactionType}
              loadOptions={loadTransactionOptions}
              isSearchable={false}
            />
          </div>

          <div className="col-8 mb-2">
            <CustomInput
              label="Pontential ROI"
              id="pontentialROI"
              type="text"
              disabled={!isPontentialROIOverride}
              error={errors.pontentialROI}
              rest={{
                ...register('pontentialROI', {
                  value: getValues('pontentialROI') || undefined,
                }),
              }}

            />
          </div>

          <div className="col-4 mb-2">
            <FormControl sx={{ height: '69px', width: '100%' }}>
              <FormControlLabel
                sx={{ mr: 0, display: 'flex', gap: '12px', ml: 0, '& .MuiCheckbox-root': { p: 0 } }}
                control={<Checkbox
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  onChange={e => setisPontentialROIOverride(e.target.checked)} />}
                {...register('pontentialROIOverride', {
                  value: getValues('pontentialROIOverride') || undefined,
                })}
                label={
                  <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    Override pontential ROI
                  </Typography>
                }
              />
            </FormControl>
          </div>

          <div className="col-12 mb-2">
            <DatePickerInput
              label="Contract date"
              id="contractDate"
              value={getValues('closingDate') === undefined ? '' : getValues('closingDate')}
              error={errors.contractDate}
              rest={{
                ...register('contractDate', {
                  value: getValues('contractDate') === undefined ? '' : getValues('contractDate'),
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <DatePickerInput
              label="EMD deadline"
              id="emdDeadline"
              value={getValues('closingDate') === undefined ? '' : getValues('closingDate')}
              error={errors.emdDeadline}
              rest={{
                ...register('emdDeadline', {
                  value: getValues('emdDeadline') === undefined ? '' : getValues('emdDeadline'),
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <DatePickerInput
              label="Dueiligence period"
              id="dueiligencePeriod"
              value={getValues('closingDate') === undefined ? '' : getValues('closingDate')}
              error={errors.dueiligencePeriod}
              rest={{
                ...register('dueiligencePeriod', {
                  value: getValues('dueiligencePeriod') === undefined ? '' : getValues('dueiligencePeriod'),
                }),
              }}
            />
          </div>


          <div className="col-12 mb-2">
            <DatePickerInput
              label="Closing date"
              id="closingDate"
              error={errors.closingDate}
              value={getValues('closingDate') === undefined ? '' : getValues('closingDate')}
              rest={{
                ...register('closingDate', {
                  value: getValues('closingDate') === undefined ? '' : getValues('closingDate'),
                }),
              }}
            />
          </div>


          <div className="col-12 mb-4">
            <Autocomplete
              id="address"
              label="Address"
              onPlaceSelect={onPlaceChanged}
              error={errors.address?.fullAddress}
              rest={{
                ...register('address.fullAddress', {
                  value: getValues('address.fullAddress') || '',
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <CustomInput
              label="City"
              id="address"
              type="text"
              error={errors.address?.city}
              rest={{
                ...register('address.city', {
                  value: getValues('address.city') || '',
                }),
              }}
            />
          </div>

          <div className="col-12 mb-2">
            <CustomInput
              label="ZIP code"
              id="address"
              type="text"
              error={errors.address?.postalCode}
              rest={{
                ...register('address.postalCode', {
                  value: getValues('address.postalCode') || '',
                }),
              }}
            />
          </div>


          <div className="col-12 mb-4">
            <Controller
              control={control}
              name="note"
              render={({ field: { onBlur, onChange, value } }) => (
                <>
                  <TextField
                    multiline={true}
                    sx={drawerTextArea}
                    placeholder="Add a note"
                    label="Add a note"
                    id="note"
                    value={value}
                    error={errors.note !== undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  <FieldError error={errors.note} />
                </>
              )}
            />
          </div>

          <div className="col-12">
            <Button
              type="submit"
              className="w-100"
              size="large"
              disabled={createMyPropertyMutation.isLoading || getMyPropertyByIdQuery.isLoading}
              startIcon={
                (createMyPropertyMutation.isLoading || getMyPropertyByIdQuery.isLoading) && (
                  <CircularProgress size={20} />
                )
              }
            >
              {itemIsUpdate ? 'Edit' : 'Create New'} Pipeline
            </Button>
          </div>
        </form>
      </Box>
    );

  return (
    <>
      <Button size="large" variant="text" onClick={() => setIsOpenCreateDrawer(true)}>
        Create new pipeline +
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={createMyPropertyMutation.isSuccess || getMyPropertyByIdQuery.isSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {createMyPropertyMutation.isError || getMyPropertyByIdQuery.isError
            ? (createMyPropertyMutation.error as any)?.message || (getMyPropertyByIdQuery.error as any)?.message
            : `Your contact ${itemIsUpdate ? 'updated' : 'created'} successlufy!`}
        </Alert>
      </Snackbar>
      <Drawer anchor="right" variant="temporary" open={isOpenCreateDrawer} onClose={onCloseDrawer}>
        {renderCreate}
        <TabListDialog ref={dialogRef} click={(id: string, type: number) => handleClik(id, type)} />
      </Drawer>

    </>
  );
};

export { Create };
