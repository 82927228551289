/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { FC } from 'react';
import { ColumnInstance } from 'react-table';
import { ExportHistory } from '../../../core/_models';

type Props = {
  column: ColumnInstance<ExportHistory>;
};

const ExportHeaderColumn: FC<Props> = ({ column }) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
);

export { ExportHeaderColumn };
