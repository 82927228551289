import React from 'react';
import { Box, Typography } from '@mui/material';
import { NotificationItem, StyledSwitch, SwitchContainer, SwitchWrapper } from './notification.sx';
import { EnabledTypeState, NotificationEnabledType, NotificationSettingType } from '../../../../api/models';
import logger from '../../../../utils/logger';
import { useMutation, useQueryClient } from 'react-query';
import { updateNotification } from '../../../../api/notifications';
import { toast } from 'react-hot-toast';

const NotificationSetting = ({ notification }: { notification: NotificationSettingType }) => {
  const queryClient = useQueryClient();
  const isEmailEnabled =
    notification.enabledTypes.find((t) => t.enabledType === NotificationEnabledType.Email)?.isEnabled || false;
  const isPushEnabled =
    notification.enabledTypes.find((t) => t.enabledType === NotificationEnabledType.Push)?.isEnabled || false;

  const { mutate: updateNotificationSetting } = useMutation(
    ({ id, enabledTypeState }: { id: number; enabledTypeState: EnabledTypeState }) =>
      updateNotification(id, enabledTypeState),
    {
      onSuccess: () => {
        logger.log('Notification setting updated successfully.');
        toast.success('Notification setting updated successfully.');
        queryClient.invalidateQueries(['notificationSettings']);
      },
      onError: (error) => {
        logger.error('Failed to update notification setting', error);
        toast.error('Failed to update notification setting');
      },
    }
  );

  const handleChange = (type: NotificationEnabledType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = event.target.checked;
    const updatedState: EnabledTypeState = { enabledType: type, isEnabled };
    updateNotificationSetting({
      id: notification.id,
      enabledTypeState: updatedState,
    });
  };

  return (
    <NotificationItem>
      <Box mb={1}>
        <Typography variant="h6" sx={{ fontSize: '1.2rem', fontWeight: 600, mb: 0.5 }}>
          {notification.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {notification.description}
        </Typography>
      </Box>

      <SwitchContainer>
        <SwitchWrapper>
          <StyledSwitch
            checked={isEmailEnabled}
            onChange={handleChange(NotificationEnabledType.Email)}
            inputProps={{ 'aria-label': 'Email notifications' }}
          />
          <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600, ml: 1 }}>
            Email
          </Typography>
        </SwitchWrapper>

        <SwitchWrapper>
          <StyledSwitch
            checked={isPushEnabled}
            onChange={handleChange(NotificationEnabledType.Push)}
            inputProps={{ 'aria-label': 'Push notifications' }}
          />
          <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600, ml: 1 }}>
            Push
          </Typography>
        </SwitchWrapper>
      </SwitchContainer>
    </NotificationItem>
  );
};

export default NotificationSetting;
