import React from "react";
import { Card, CardContent, Typography, Box, CardMedia } from "@mui/material";
import { getImageUrl } from "./_requests";

// Define the props type
interface RelatedPropertyProps {
    image: string;
    address: string;
    propertyType: string;
}

// Functional Component with Props
const RelatedPropertyCard: React.FC<RelatedPropertyProps> = ({ image, address, propertyType }) => {
    return (
        <Card sx={{ display: "flex", alignItems: "center", maxWidth: 600, borderRadius: 2 }}>
            <CardMedia
                component="img"
                sx={{ width: 120, height: 120, borderRadius: 1, margin: 1 }}
                image={getImageUrl(image)}
                alt="Property"
            />
            <Box sx={{ flex: 1 }}>
                <CardContent>
                    <Typography variant="body2" color="textSecondary">
                        {address}
                    </Typography>
                    <Typography variant="subtitle2" color="textPrimary" sx={{ marginTop: 1 }}>
                        {propertyType}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    
    );
};

export default RelatedPropertyCard;
