/* eslint-disable react/react-in-jsx-scope */
import { QueryResponseProvider } from './core/QueryResponseProvider';
import { QueryRequestProvider } from './core/QueryRequestProvider';
import { StageQueryResponseProvider } from './core/StageQueryResponse';
import { ListViewProvider, useListView } from './core/ListViewProvider';
import { DealpipelineHeader } from './components/header/Header';
import { DealPipelineTable } from './components/table/DealPipelineTable';
import { Details } from './components/details/Details';
import { StageList } from './components/stageList/StageList';

const DealPipelinePage = () => {
  const { itemIdForUpdate } = useListView();

  return (
    <>
      <DealpipelineHeader />
      <StageList />
      <DealPipelineTable />
      {itemIdForUpdate !== undefined && <Details />}
    </>
  );
};

const DealPipeline = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <StageQueryResponseProvider>
          <ListViewProvider>
            <DealPipelinePage />
          </ListViewProvider>
        </StageQueryResponseProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

export { DealPipeline };
