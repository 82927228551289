import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListSubheader,
    AppBar,
    Tabs,
    Tab,
    Box,
    TextField,
    IconButton,
    DialogContent,
    DialogActions,
    Button,
    ListItemButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from 'react-query';
import { QUERIES } from 'app/constants/consts';
import { getDealPiplineById, getRelatedListing, getRelatedMyProperty } from '../../core/_requests';
import logger from 'app/utils/logger';
import RelatedPropertyCard from '../../core/RelatedProperty';

interface TabListDialogHandle {
    openDialog: () => void;
    closeDialog: () => void;
}

interface TabListDialogProps {
    click?: (id: string, type: number) => void;
}

const TabListDialog = forwardRef<TabListDialogHandle, TabListDialogProps>((props, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>('');


    useImperativeHandle(ref, () => ({
        openDialog: () => setOpen(true),
        closeDialog: () => setOpen(false),
    }));


    const {
        isLoading,
        data: property,
        error,
        refetch
    } = useQuery(
        `${QUERIES.MYPROPERTY_LIST}-${tabIndex}-related-${tabIndex}`,
        () => {
            return tabIndex === 0 ? getRelatedMyProperty(`name=${searchQuery}`) :
                getRelatedListing(`name=${searchQuery}`);
        },
        {
            cacheTime: 0,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: true,
            onError: (err) => {
                logger.error(err);
            },
        }
    );


    function reloadData(): void {
        refetch();
    }

    
    const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
        setTabIndex(newValue);
        refetch();
    };
    
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
        >

            <DialogTitle>Select Related</DialogTitle>
            <DialogContent>
                <List
                    subheader={
                        <ListSubheader component="div" sx={{ paddingTop: 2, paddingBottom: 2 }}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                    aria-label="list tabs"
                                    variant="fullWidth"
                                    sx={{
                                        '& .MuiTabs-indicator': {
                                            display: 'none', // Hides the underline
                                        },
                                    }}
                                >
                                    <Tab label="MyProperty" sx={{ backgroundColor: 'transparent' }} />
                                    <Tab label="MyListing" sx={{ backgroundColor: 'transparent' }} />
                                </Tabs>
                            </AppBar>
                            <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1, paddingTop: 3 }}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <IconButton color="primary" onClick={() => reloadData()}>
                                    <SearchIcon />
                                </IconButton>
                            </Box>
                        </ListSubheader>
                    }
                >
                    {property && property.values.length > 0 ? (
                        property.values.map((item) => (
                            <ListItemButton key={item.id} onClick={() => props.click(item.id, tabIndex)}>
                                <RelatedPropertyCard image={item.image} address={item.address.fullAddress} propertyType={item.propertyType} />
                            </ListItemButton>
                        ))
                    ) : (
                        <ListItem>No results found</ListItem>
                    )}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
});

export default TabListDialog;
