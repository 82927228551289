const QUERIES = {
  CONTACT_LIST: 'contact-list',
  CONTACT_GROUP_LIST: 'contact-group-list',
  DEAL_PIPELINE_STAGE: 'deal-pipeline-stage',
  PROPERTY_LIST: 'property-list',
  REMINDER_LIST: 'reminder-list',
  MYPROPERTY_LIST: 'myproperty-list',
  EPORT_LIST: 'export-list',
  LISTINGS: 'listings',
  DEALPIPELINE_LIST: 'dealpipeline-list',
  PIPELINESTAGE_LIST: 'dealpipeline-list',
  ACTIVITIES: 'activities',
};

export { QUERIES };
