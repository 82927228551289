export function formatHumanizedDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

const units = [
    {name: "year", ms: 31536000000},
    {name: "month", ms: 2628000000},
    {name: "day", ms: 86400000},
    {name: "hour", ms: 3600000},
    {name: "minute", ms: 60000},
    {name: "second", ms: 1000},
];
const rtf = new Intl.RelativeTimeFormat("en", {numeric: "auto"});

export function formatRelativeTime(date: Date): string {
    const today=new Date();
    if (!date) {
        return "just now";
    }
       
    const elapsed = date.getTime() - today.getTime();
    return relativeTimeFromElapsed(elapsed);
}

function relativeTimeFromElapsed(elapsed: number): string {
    for (const unit of units) {
        if (Math.abs(elapsed) >= unit.ms || unit.name === "second") {
            return rtf.format(Math.round(elapsed / unit.ms), unit.name as Intl.RelativeTimeFormatUnit);
        }
    }
    return "just now";
}
