import { ActivityLog, ActivityStatus } from '../core/_models';
import { useMutation, useQueryClient } from 'react-query';
import React, { useState } from 'react';
import { acceptActivity, declineActivity, getImageUrl } from '../core/_requests';
import { QUERIES } from '../../../../constants/consts';
import toast from 'react-hot-toast';
import logger from '../../../../utils/logger';
import { PropertyImage, RequestContainer } from './property-requests.sx';
import { Box, Button, Typography } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import Details from './Details';

interface Props {
  status: ActivityStatus;
  request: ActivityLog;
  showActions?: boolean;
}

const RequestCard = ({ status, request, showActions = true }: Props) => {
  const queryClient = useQueryClient();
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const acceptMutation = useMutation(() => acceptActivity(request.id.toString()), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.ACTIVITIES}`, status]);
      toast.success('Activity accepted successfully');
    },
    onError: (error) => {
      logger.error('Failed to accept activity:', error);
      toast.error('Failed to accept activity');
    },
  });

  const declineMutation = useMutation(() => declineActivity(request.id.toString()), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.ACTIVITIES}`, status]);
      toast.success('Activity declined successfully');
    },
    onError: (error) => {
      logger.error('Failed to decline activity:', error);
      toast.error('Failed to decline activity');
    },
  });

  const handleAccept = () => {
    acceptMutation.mutate();
  };

  const handleDecline = () => {
    declineMutation.mutate();
  };

  const isLoading = acceptMutation.isLoading || declineMutation.isLoading;

  return (
    <RequestContainer>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <PropertyImage
            src={getImageUrl(request.propertyInfo.imageUrl)}
            alt={`Property at ${request.propertyInfo.address.city}`}
          />
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
              {request.propertyInfo.name}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <LocationOn sx={{ fontSize: 16, color: 'text.secondary' }} />
              <Typography variant="body2" color="text.secondary">
                {request.propertyInfo.address.fullAddress}
              </Typography>
            </Box>
          </Box>
        </Box>
        {showActions ? (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="contained" onClick={handleAccept} disabled={isLoading}>
              {acceptMutation.isLoading ? 'Accepting...' : 'Accept'}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleDecline}
              disabled={isLoading}
              sx={{ color: 'text.primary', borderColor: 'divider' }}
            >
              {declineMutation.isLoading ? 'Declining...' : 'Decline'}
            </Button>
          </Box>
        ) : (
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => setIsDetailOpen(true)}
            sx={{ color: 'text.primary', borderColor: 'divider' }}
          >
            More Info
          </Button>
        )}
      </Box>
      {isDetailOpen && (
        <Details
          userId={request.userInfo.id}
          requestInfo={request.requestInfo}
          isOpen={isDetailOpen}
          onClose={() => setIsDetailOpen(false)}
        />
      )}
    </RequestContainer>
  );
};
export default RequestCard;
