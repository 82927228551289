
import * as React from 'react';
import type { SVGProps } from 'react';
const FourSquare = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M3.33594 6.66658H4.66927C6.0026 6.66658 6.66927 5.99992 6.66927 4.66659V3.33325C6.66927 1.99992 6.0026 1.33325 4.66927 1.33325H3.33594C2.0026 1.33325 1.33594 1.99992 1.33594 3.33325V4.66659C1.33594 5.99992 2.0026 6.66658 3.33594 6.66658Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5} />
        <path
            d="M11.3359 6.66658H12.6693C14.0026 6.66658 14.6693 5.99992 14.6693 4.66659V3.33325C14.6693 1.99992 14.0026 1.33325 12.6693 1.33325H11.3359C10.0026 1.33325 9.33594 1.99992 9.33594 3.33325V4.66659C9.33594 5.99992 10.0026 6.66658 11.3359 6.66658Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5} />
        <path
            d="M11.3359 14.6666H12.6693C14.0026 14.6666 14.6693 13.9999 14.6693 12.6666V11.3333C14.6693 9.99992 14.0026 9.33325 12.6693 9.33325H11.3359C10.0026 9.33325 9.33594 9.99992 9.33594 11.3333V12.6666C9.33594 13.9999 10.0026 14.6666 11.3359 14.6666Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5} />
        <path
            d="M3.33594 14.6666H4.66927C6.0026 14.6666 6.66927 13.9999 6.66927 12.6666V11.3333C6.66927 9.99992 6.0026 9.33325 4.66927 9.33325H3.33594C2.0026 9.33325 1.33594 9.99992 1.33594 11.3333V12.6666C1.33594 13.9999 2.0026 14.6666 3.33594 14.6666Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5} />
    </svg>
);
export default FourSquare;

