/* eslint-disable react/react-in-jsx-scope */
import { Column } from 'react-table';
import { DealPipelineSelectionCell } from './DealPipelineSelectionCell';
import { DealPipelineSelectionHeader } from './DealPipelineSelectionHeader';
import { DealPipelineCustomHeader } from './DealPipelineCustomHeader';
import { DealPipelineCustomCell, DealPipelineImageCell } from './DealPipelineCustomCell';
import { DealPipelineActionCell } from './DealPipelineActionCell';
import { DealPipeline } from '../../../core/_models';
import {  formatRelativeTime } from '../../../core/dateHelper';
import { DealPipelineLeadCell } from './DealPipelineLeadCell';

const contactsColumns: ReadonlyArray<Column<DealPipeline>> = [
  {
    Header: (props) => <DealPipelineSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <DealPipelineSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <DealPipelineCustomHeader tableProps={props} title="#" className="min-w-150px text-center" />,
    id: 'Image',
    Cell: ({ ...props }) => (
      <DealPipelineImageCell image={props.data[props.row.index].address.city} address={props.data[props.row.index].address.fullAddress} />
    ),
  },
  {
    Header: (props) => <DealPipelineCustomHeader tableProps={props} title="Name" className="min-w-150px" />,
    id: 'Name',
    Cell: ({ ...props }) => <DealPipelineCustomCell title={props.data[props.row.index].name} />,
    width: 300,
  },
  {
    Header: (props) => <DealPipelineCustomHeader tableProps={props} title="Address" className="min-w-150px" />,
    id: 'Address',
    Cell: ({ ...props }) => <DealPipelineCustomCell title={props.data[props.row.index].address.fullAddress} />,
    width: 300,
  },
  {
    Header: (props) => (
      <DealPipelineCustomHeader tableProps={props} title="Deal Lead" className="min-w-150px text-center" />
    ),
    id: 'DealLead',
    Cell: ({ ...props }) => <DealPipelineCustomCell title={props.data[props.row.index]?.lead?.name}  className="text-center"  />    
  },
  {
    Header: (props) => (
      <DealPipelineCustomHeader tableProps={props} title="Property Type" className="min-w-150px text-center" />
    ),
    id: 'PropertyType',
    Cell: ({ ...props }) => (
      <DealPipelineCustomCell title={props.data[props.row.index]?.propertyType?.name} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <DealPipelineCustomHeader tableProps={props} title="Deal Type" className="min-w-150px text-center" />
    ),
    id: 'DealType',
    Cell: ({ ...props }) => (
      <DealPipelineCustomCell title={props.data[props.row.index]?.dealType} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <DealPipelineCustomHeader tableProps={props} title="Days in Pipeline" className="min-w-150px text-center" />
    ),
    id: 'DaysInPipeline',
    Cell: ({ ...props }) => (
      <DealPipelineCustomCell title={formatRelativeTime(new Date(props.data[props.row.index].createdTime))} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <DealPipelineCustomHeader tableProps={props} title="Deal stage" className="min-w-150px text-center" />
    ),
    id: 'DealStage',
    Cell: ({ ...props }) => (
      <DealPipelineCustomCell title={props.data[props.row.index]?.stage.name} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <DealPipelineCustomHeader tableProps={props} title="Profit" className="min-w-150px text-center" />
    ),
    id: 'Profit',
    Cell: ({ ...props }) => (
      <DealPipelineCustomCell title={props.data[props.row.index]?.pontentialROI} className="text-center" />
    ),
  },
  // {
  //   Header: (props) => <DealPipelineCustomHeader tableProps={props} title="Title" className="min-w-150px text-center" />,
  //   id: 'Title',
  //   Cell: ({ ...props }) => <DealPipelineCustomCell title={props.data[props.row.index]?.title} className="text-center" />,
  // },
  {
    Header: (props) => (
      <DealPipelineCustomHeader tableProps={props} title="Actions" className="min-w-150px text-center" />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <DealPipelineActionCell id={props.data[props.row.index].id} />,
  },
  
];

export { contactsColumns };
