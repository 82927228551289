/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { FC } from 'react';
import { IconButton } from '@mui/material';
import { Import, Trash } from 'app/components/SvgIcons';
import { getFileAbsoluteUrl } from 'app/constants/helpers';

type Props = {
  url: string;
  name: string;
};

const ExportActionCell: FC<Props> = ({  url, name }) => {


  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <a href={getFileAbsoluteUrl(`/${url}`)} download={`${name}.csv`} className="bg-warning p-3 text-black fs-7" >
          <Import />
        </a>
      </div>
    </>
  );
};

export { ExportActionCell };
