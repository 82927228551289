/* eslint-disable padding-line-between-statements */
import { httpClient } from 'app/api/http/http-client';
import { DealPipelineQueryResponse, DealPipeline, StageQueryResponse, Stages, RelatedProperty, RelatedPropertyQueryResponse } from './_models';
import axios from 'axios';
import defaultImage from '../../../../../assets/default-image.png';

const getDealPiplines = (query: string): Promise<DealPipelineQueryResponse> => {
  return httpClient.get(`/DealPipline?${query}`);
};

const getDealPiplinesMinimal = (query: string): Promise<DealPipelineQueryResponse> => {
  return httpClient.get(`/DealPipline/minimal?${query}`);
};

const getStages = (): Promise<StageQueryResponse> => {
  return httpClient.get('/PiplineStage');
};

const getProperties = (): Promise<RelatedPropertyQueryResponse> => {
  return httpClient.get('/relatedProperties');
};

const getDealPiplineById = (id: number): Promise<DealPipeline> => {
  return httpClient.get(`/DealPipline/${id}`);
};

const getRelatedListing = (query: string): Promise<RelatedPropertyQueryResponse> => {
  return httpClient.get(`/Property/list?${query}`);
};
const getRelatedMyProperty = (query: string): Promise<RelatedPropertyQueryResponse> => {
  return httpClient.get(`/MyProperties/list?${query}`);
};


const createDealPipline = (body: DealPipeline): Promise<boolean> => {
  return httpClient.post('/DealPipline', body);
};

const createStage = (body: Stages): Promise<boolean> => {
  return httpClient.post('/PiplineStage', body);
};

const updateDealPipline = (body: DealPipeline): Promise<boolean> => {
  return httpClient.put(`/DealPipline/${body.id}`, body);
};

const updateStage = (id: number, name: string): Promise<boolean> => {
  return httpClient.put(`/PiplineStage/${id}/name`, name);
};

const deleteSelectedMyProperties = (ids: number[]): Promise<void> => {
  const requests = ids.map((id) => httpClient.delete(`/UserContact/${id}`));
  return axios.all(requests).then(() => {});
};

const deleteStage = (id: number): Promise<void> => {
  return httpClient.delete(`/PiplineStage/${id}/2`);
};

const changeStage = (body: any) => {
  return httpClient.put(`/DealPipline/stage/${body.listId}`, body.list);
};

const getImageUrl = (image: string) => {
  return image?.length > 0 ? `https://test.urexc.com/f/${image}` : defaultImage;
};

export {
  getDealPiplines,
  getStages,
  getDealPiplineById,
  createDealPipline,
  createStage,
  updateDealPipline,
  updateStage,
  deleteSelectedMyProperties,
  deleteStage,
  changeStage,
  getImageUrl,
  getProperties,
  getRelatedListing,
  getRelatedMyProperty,
  getDealPiplinesMinimal
};
