/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { DealStageListButton } from './DealStageListButton';
import { useStageQueryResponse } from '../../core/StageQueryResponse';
import { Stages as CL } from '../../core/_models';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { DeleteModal } from './DeleteModal';

const StageList = () => {
  const [itemId, setItemId] = useState<number | null>(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setItemId(null);
    setShow(false);
  };
  const handleShow = (id: number) => {
    setItemId(id);
    setShow(true);
  };

  const {
    state: { StageId },
    updateState,
  } = useQueryRequest();
  const { response } = useStageQueryResponse();
  const data = response as any;

  const updatedState = (stageId?: number) => updateState({ StageId: stageId });

  return (
    <div className="d-flex flex-wrap gap-3 mt-4 mt-md-0">
      <div style={{ marginRight: 12, alignContent: 'center' }}>
        <DealStageListButton title="Deal Pipeline" updatedState={() => updatedState()} isActive={!StageId} />
      </div>
      {data?.map((item: CL) => (
        <DealStageListButton
          isEditableMode
          key={item.id}
          id={item.id}
          title={item.name}
          isActive={StageId === item.id}
          updatedState={() => updatedState(item.id)}
          onDeleteShow={handleShow}
          canDelete={item.type === 3}
        />
      ))}
      <DealStageListButton isCreateMode />
      <DeleteModal show={show} handleClose={handleClose} id={itemId} />
    </div>
  );
};

export { StageList };
